//解决方案
import React, {
  useState,
  useEffect,
  // ,useRef
} from "react";
import Banner from "../../components/banner/banner";
import Form from "../../components/Form";
import { Tabs } from "antd";
import useInView from "../../components/hook/hook";
import "./Settle.scss";

import SettleArticle from "./SettleArticle";

export default function Settle() {
  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const text = "解决方案";
  const text_yw = "SOLUTIONS";
  const imgUrl = require("../../assets/imgs/settle/settle_banner.jpg");
  //监听
  const [targetRef, inView] = useInView();
  const [targetRef2, inView2] = useInView();
  // 定位
  // const solutionFooterRef = useRef(null);
  // const scrollToFooter = () => {
  //   if (solutionFooterRef.current) {
  //     solutionFooterRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // 子项
  const outWater = [
    "排水防涝高效应急调度",
    "排水管网运行监测",
    "分散式污水设施运行管控",
    "污水厂自动化控制系统",
    "智能拉运",
  ];
  const inWater = [
    "智慧厂网调度一体化",
    "水务企业管理一体化",
    "二次供水集中化管控",
    "净水厂自动化控制系统",
    "智能远传抄表",
  ];

  const inWaterData = [
    {
      title: "智慧厂网调度一体化解决方案",
      time: "",
      p: "面向水务企业调度管理部门，以“监控一体化、数据集中化、管控平台化、支撑智慧化”为建设思路，打造供水“智慧中枢”，助力城市供水安全。",
      yewutongdian: {
        p: "",
        list: [
          "设备无法实时在线监控，运行状态不清、监控管理不明。",
          "依靠人工经验调度，无数据依据，调度管理不科学。",
          "人工方式运营管理，业务部门协同性不足，效率低，应急力不足。",
          "水司电耗药耗高，需要降本增效。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [],
        image: require("../../assets/imgs/settle/guanwangdiaodu/fuwuneir.png"),
      },
      fanganjieshao: {
        p: "贯穿供水全链路，以调度管理为主线贯串水厂、管网、巡检等关键事务流，打造““无缝、闭环、智慧”的一体化管控平台，建立厂网一体化综合调度应用，实现“分析诊断、决策分析、执行监控、效果反馈”管理服务一体化闭环流程。",
        list: [
          "调度系统：可实现厂站及管网设备的统一监测，提供各类调度专题分析、调度方案、调度报表等功能。",
          "GIS一张图：在地图上实时展示各个监测设备的空间分布，实现管网实时压力、流量、水位等数据的查看功能，可实现管网地图的可视化操作、查询、统计与分析等。",
          "巡检系统：制定巡检计划与路线，管控巡检任务，提供巡检统计与分析报表。",
          "统一工单系统：工单的统一派单、处理、查询与统计。",
          "厂站运行监控：水厂工艺设备的可视化监控、水厂日常运维的任务管理与报表分析工具，设置预警值，支持自动预警。",
        ],
        image: "",
      },
      fanganjiazhi: {
        p: "",
        list: [
          "调度方案更科学：全链路监测设备，实时感知数据获取，提供科学调度数据依据。",
          "工作开展更高效：实现爆管快速定位、抢维修及时高效，解决流程慢、执行效率低、工作难考核弊病。",
          "运营管理更先进：解决生产、管网、巡检等部门数据孤岛、各自为政问题，实现高效协同。",
          "节能降耗更有效：支持厂站远程监控、智慧调度，提供智能化分析报表，实现节能降耗。",
        ],
        image: "",
      },
    },
    {
      title: "水务企业管理一体化解决方案",
      time: "",
      p: "为水务企业提供办公管理、工程管理、资产管理、指标分析、移动办公管理、数据安全管理、智慧决策等一系列高效协同的管理手段与工具，赋能水务企业经营管理，提升企业经营服务水平。",
      yewutongdian: {
        p: "为水务企业提供办公管理、工程管理、资产管理、指标分析、移动办公管理、数据安全管理、智慧决策等一系列高效协同的管理手段与工具，赋能水务企业经营管理，提升企业经营服务水平。",
        list: [
          "业务流转流程不清晰，责任不明确，缺少体系化的审批与反馈机制。",
          "管理无抓手，缺少办公管理、工程管理、设备管理等信息化管理工具。",
          "缺乏有效的经营数据指标与分析工具，难以辅助管理者考核与决策。",
          "缺少一体化的移动管理工具，经营管理不够灵活便捷。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "整合水司现有系统，建立一体化的企业管理系统，为水务企业提供水务信息化、一体化门户、手机APP（微信小程序）、OA系统、统一工单系统、企业经营BI、工程管理系统、设备管理系统等面向企业经营管理的一体化方案。",
        list: [],
        image: "",
      },
      fanganjieshao: {
        p: "以统一工单系统为核心，打通客服热线、管网巡检、管网抢维修、管网探漏等各业务子系统，是各业务系统工单处理与审批的统一流转中心，并可依托大数据平台能力，为管理者提供可视化的经营指标、分析报表、决策建议等一系列智慧化工具，无缝衔接智慧水务调度大屏，赋能企业经营管理，提升企业整体形象。",
        list: [],
        image: "",
      },
      fanganjiazhi: {
        p: "",
        list: [
          "理清流程：协助水务集团，建立标准化的生产与业务管理的流程，可灵活配置，自主可控。",
          "高效协同：一体化门户登录，打通各业务流程，内外业统一管理，与OA工作流无缝对接，促进企业的高效协同管理。",
          "精细化管理：提供面向不同业务专题的可视化“一张图”，各类业务指标轻松可查，经营分析有据可依。",
        ],
        image: "",
      },
    },
    {
      title: "二次供水集中化管控解决方案",
      time: "",
      p: "二次供水关乎人民群众饮水安全与正常稳定生活，二供泵房往往建设标准不一、环境复杂、管理难度高，本方案可为水务企业提供二次供水的接收、管理、决策、降耗、运维等支撑，保障最后一公里用水安全。",
      yewutongdian: {
        p: "",
        list: [
          "水质污染风险高：设施由于密封不严实、冲洗消毒不及时等原因，细菌滋生，影响用水安全。",
          "供水漏损严重：现有技术标准不规范不系统；部分设施老旧，滴漏严重，故障频发。",
          "管理难度大：物业管理水平参差不齐，小区供水管理乱象，用户投诉无门，深受其害。",
          "监管不到位：现有安全意识薄弱，管理隐患众多，无法进行信息化处理，无法实现远程监控。",
          "统一运维难：多个厂家多个孤立系统，数据孤岛问题严重，无法进行统一的管控。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "泵站运行监测：利用数字孪生技术对泵站进行三维建模，生动展示泵站的工艺流程，实时在线监测泵站的电机运行、阀门状态、流量等指标，可远程进行实时监控，及时掌握泵站运行状况。",
          "供水状态监控：供水流量及压力监控，二供水质监控，水箱液位及阀门联动控制。",
          "二供环境监控：温度、湿度、烟雾监控，防淹及排水监控，门禁、视频及防入侵监控。",
          "信息化支撑：PLC数据采集及远程操控，统一监控调度及运营分析，二供档案管理。",
        ],
        image: "",
      },
      fanganjieshao: {
        p: "采用物联网数据采集、大数据分析、三维仿真、数据挖掘等技术，帮助水务企业实现二次供水管理的远程化、数字化、集约化和智能化的目标。",
        list: [],
        image: require("../../assets/imgs/settle/ercigongshui/inwater_ercigongshui_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [
          "提高供水保障能力：通过运行实时监控、在线预警和故障报警，提高维修预防性和高效性，降低故障发生率，达到“供水稳定、水质安全、维修快捷”的服务水平。",
          "提高精细管理水平：基于智能网关对二供设施运行数据的统一采集接入，在管控平台上进行集中监控，实现二供管理工作全程化、细则化与标准化，实现精细化管理。",
          "提高运营决策能力：通过供水效率、供水质量、供水稳定性、运行能耗等多个场景专题分析及数据报表，为二供提标优化、节能降耗、管理流程升级提供决策依据。",
          "降低维护服务成本：智能设定泵组启停时间，智能远程控制替代简单重复操作，降低运行和维护成本；通过高效配置运维服务流程，全程实时跟踪管理，提高运维效率。",
        ],
        image: "",
      },
    },
    {
      title: "净水厂自动化控制系统解决方案",
      time: "",
      p: "",
      yewutongdian: {
        p: "",
        list: [],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "自动化控制升级：通过PLC技术升级改造自控系统，实现生产过程自动控制、报警、自动保护、自动操作、自动调节以及各工艺流程中在线实时监控。系统定期在实践实验的基础上升级，包括人工现场升级和远程升级。",
          "实现全过程控制：包括远程取水、混凝沉淀、加药、反冲洗、脱泥、供水等全过程自动控制。",
          "恒压供水：恒压供水控制能够保持供水压力的恒定，确保水泵机组调度合理、运行稳定，可使供水和用水之间保持平衡，即用水多时供水也多，用水少时供水也少，从而提高供水质量。",
          "集中监测，分散控制：以自动控制系统代替人的工作，运行、设备、管理达到互通互联，降低人员劳动负荷，实现水厂少人值守或无人值守。",
          "水厂二维码系统：将水厂的各种设备功能和操作注意事项等信息融入系统，借助手机扫描功能即可随时查看设备的功能介绍，方便市民开放日的参观活动，也有利于操作人员进入构筑物前提前了解注意事项，提高安全措施，便于安全运行。",
        ],
        image: "",
      },
      fanganjieshao: {
        p: "系统具备数据采集、比较、学习、优化功能，每一次生产运行的环境条件参数与产生的结果都有完整明确的对应结果。集合中控室控制中心、水源井测控系统、消毒系统、水质在线监测系统、二次变频恒压供水系统于一体，保障水厂供水能力。",
        list: [],
        image: require("../../assets/imgs/settle/shuichangzikong/shuichangzikong_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [
          "智能自动：加药加氯量、反冲频率、排泥周期等控制参数可根据水处理工艺的改变合理方便调整；砂滤恒液位、滤池自动反冲洗、恒压供水等复杂过程经过缜密的逻辑算法编程得以实现。",
          "稳定可靠：水厂净水的工艺过程相对独立，单体设备多，设备距离远，采集种类和数据庞大，并且水厂水处理有重要的连续性、不可替代性和不可间断性要求。控制系统方案选用PLC集散控制与现代网络通讯相结合的先进控制系统。主干控制通讯使用冗余光纤环网，单工艺过程使用集散控制结构，即使某点出现问题也不会影响其他装置和整个制水过程。",
          "经济实用：无人或少人值守降低人工成本。精细化控制保证低药耗、低能耗降低生产成本。集约化调度与监管，实现管理透明化，降低管理成本。",
          "方便扩展：我司选用行业内先进的PLC、触摸屏、电源等产品，可保证系统的兼容性。各控制设备可以互换使用，提高了维护便利性，节省成本。",
        ],
        image: "",
      },
    },
    {
      title: "智能远传抄表",
      time: "",
      p: "适用于远程监测工厂、酒店、学校、医院等大用户的用水量，各区域的用水总量进行自动化抄表。",
      yewutongdian: {
        p: "",
        list: [
          "抄表方式落后，人工抄表费时费工费力。",
          "故障排查难，数据异常或设备故障时难以找到设备定位。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "支持接入各类智能水表远传数据，实时或定时监测用水流量数据，抄收数据记录留存，并远程传输到监测管理平台。",
          "支持出现设备故障、连续走零、倒走方向等大用户水表运行异常状况时及时报警。",
          "支持用户用水量汇总（可按日、按月、自定义期限），提供流量数据实时统计分析和用户用水量对比分析，掌握用水规律。",
          "支持巡更巡检、报修处理、抄表记录查询、报警记录查询等。",
          "支持用户信息和监测点信息的匹配与管理。",
        ],
        image: require("../../assets/imgs/settle/yuachuanshuibiao/yuachuanshuibiao_fuwuneirong.png"),
      },
      fanganjieshao: {
        p: "提供多个厂家智能水表的采集监控的统一管理，保障数据质量与数据规范性，提供灵活的协议对接方式。可接入系统远程管理用户区域内所有智能水表，支持多厂家智能水表协议，实现远传抄表与在线监测，支持数据展示、智慧分析等功能，提供智能水表管理数据报表，实时呈现智能水表运行状况，为设备计量与运营维护提供支撑。",
        list: [],
        image: require("../../assets/imgs/settle/yuachuanshuibiao/yuachuanshuibiao_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [],
        image: "",
      },
    },
  ];
  const outWaterData = [
    {
      title: "排水防涝高效应急调度解决方案",
      time: "",
      p: "适用对象：各市/区（县）防汛办/水务局、排水公司、城市管理局公用设施监管处、应急管理局防汛抗旱科",
      yewutongdian: {
        p: "",
        list: [
          "监测预警滞后，被动式防治。",
          "应急情况处理不及时，隐患大。",
          "信息化手段欠缺，无法科学决策。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "排水防捞调度GIS一张图系统：整合城市积涝点、下穿式立交桥和隧道、河道、排水管网及附属设施空间信息和实时状态，可叠加人员、物资等布防动态数据，实现城市排水防涝“一张图”展现。",
          "内涝实时在线监测系统：构建天空、地面、地下三位一体的实时监测体系，实现对各监测数据统一采集、监测和分析，为内涝评估与预警报警、应急调度指挥等提供决策支撑。",
          "水力模型涝情模拟系统：实时、可互动的水力模型，对排水系统进行全局分析，快速预测积水面积、深度、积水时间、退水时间等内涝情况，为应急预案、规划改造提供支持。",
          "洪涝预警系统：通过实时在线监测与内涝模拟分析系统，提供及时的预警和告警。",
          "应急指挥调度系统：整合应急方案管理，视频会商，抢险人员、车辆、设备调度指挥功能，支撑高效科学调度。",
        ],
        image: require("../../assets/imgs/settle/paishuifanglao/paishuifanglao_fuwuneirong.png"),
      },
      fanganjieshao: {
        p: "系统融合气象、水利、水文等信息资源，依托物联网在线监测技术、云计算和大数据技术等先进手段，建立一个集感知、分析、服务、指挥、巡查五位一体的排水防涝监控体系，构建可视化的防洪排涝“一张图”和高效的应急指挥调度管理体系，提升风险防范和处置能力。",
        list: [],
        image: require("../../assets/imgs/settle/paishuifanglao/paishuifanglao_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [
          "建立立体化监测网，涝情全程监控，动态预警，主动防治。",
          "先进的水力模型，实时模拟内涝，提升科学决策水平。",
          "高效协同，统一调度，提高应急处置能力，降低风险。",
        ],
        image: "",
      },
    },
    {
      title: "排水管网运行监测解决方案",
      time: "",
      p: "适用对象：各市、区（县）水务局/住建市给排水处、城管局下属市政、排水公司、智慧城管平台集成商",
      yewutongdian: {
        p: "",
        list: [
          "缺少管网普查检测，管网家底不清。",
          "管网使用传统纸质和CAD管理，无法直观查看和分析。",
          "缺乏实时监测及分析体系，管网运行状态不明。",
          "人工管理模式，运维养护效率低。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [],
        image: "",
      },
      fanganjieshao: {
        p: "以专业化普查检测服务、管网运行监测、专业分析、运维养护管理系统，建立四位一体的运行监测体系，实现管网及附属设施信息化管理、运行实时监测和精细化运维管理，提升管网运维管理水平，保障管网健康通畅运行。",
        list: [
          `管网普查检测服务。管网普查：对排水管网位置空间信息和管网管径、材质、埋深、流向及附属设施属性信息等进行普查。管网检测：通过卫星技术、声纳等检测技术，检测管道内部健康状况。`,
          `管网运行实时监测系统。管网GIS管理：管网信息化、可视化、动态化管理。管网实时监控与预警：水质、液位、流量、智能井盖、泵站等设备监控、分析与预警。设施管理：排水分区、排水户、管网及附属设施、污水处理厂、泵站等集约化管理。`,
          `管网专业分析系统。管渠专业分析：管渠淤积分析、管渠负荷分析、雨污混接分析、大数据混接点分析、流向分析等。`,
          `管网运维养护精细化系统。计划管理：计划制定、分派、审核、调整等。计划执行：APP接收、查看、执行反馈。任务监管：工作进度、工作指导、人员定位。养护记录：方便后期查询和追溯。绩效考核：人员考核表，辅助人员绩效管理。`,
        ],
        image: require("../../assets/imgs/settle/paishuiguanwang/paishuiguanwang_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [
          "家底清：管网的现状与管网资产了然于心。",
          "全监测：实时监测预警，管网运行全掌握。",
          "精细管：运维养护精细管理，提高工作效率。",
          "看得见：管网可视化、动态化一张图管理。",
        ],
        image: "",
      },
    },
    {
      title: "分散式污水设施运行管控解决方案",
      time: "",
      p: "适用对象：各市/区（县）/乡镇政府、住建局给排水处/镇村建设指导处、分散式污水处理设备运营单位、环保集团/公司、园区管理部门、企业",
      yewutongdian: {
        p: "",
        list: [
          "水质水量不稳定，处理难达标。",
          "点多面广问题多，有效监管难。",
          "运维人员不专业，运营成本高。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "分散式污水设施GIS一张图：实现区域全部污水处理设施一张图可视化、信息化管理。",
          "远程运行监控：实现运行、工艺、指标等多方位监测，支持设施远程控制、异常报警、故障判断、报表管理等。",
          "视频监控及门禁：实时展现站内监控点情况，实现人员门禁管理。",
          "台账管理：水质监测记录和报表、工单管理、应急排故、反馈管理。",
          "设备管理：对设备进行档案台账、设备巡检、维修记录等管理。",
        ],
        image: "",
      },
      fanganjieshao: {
        p: "系统通过对分散式污水设施进行智能化改造和数据采集接入，获取设备和仪表的在线监测数据，实现污水处理设施运行情况监控、数据智能化分析处理与可视化展现、远程智能控制，发挥分散式污水处理设施价值与作用，同时降低运营单位的风险和成本。",
        list: [],
        image: "",
      },
      fanganjiazhi: {
        p: "",
        list: [
          "智管理：实时监测，远程在线管控。",
          "高安全：运维及时，水质长效达标。",
          "低风险：智能管控，设备运行稳定。",
        ],
        image: "",
      },
    },
    {
      title: "污水厂自动化控制系统解决方案",
      time: "",
      p: "",
      yewutongdian: {
        p: "",
        list: [],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "工艺流程实时监测系统：实现对污水厂的生产工艺、设备工况进行实时监视，直观展示污水处理厂每个工艺段各测点的实时数据。",
          "智能告警系统：实现对生产数据按照各类参数、阈值上下限、波动幅度、报警频次等进行自动识别和智能告警，并结合工单系统实现闭环管理。",
          "运营分析系统：查看污水处理厂关键KPI数据，以看板的形式对各个污水处理设施运行关键信息进行展示，实现能耗分析、药耗分析、水质分析、自定义分析等各类运营数据分析。",
          "工艺调度预案管理系统：通过预案编制、审核、问题诊断、问题反馈等流程，提供科学的问题处理辅助工具，提高应急处置能力。",
          "视频监控系统：实现集团各污水厂视频集中监控，方便调取查看各污水厂现场情况。",
        ],
        image: "",
      },
      fanganjieshao: {
        p: "污水厂自动化监控系统，采用二级管理的计算机监控系统，其上位系统位于中控室，用于对全厂的所有站点设备进行监控，下级控制系统位于各个工艺段PLC站点。上下级通过通信网络进行数据交换，实现上位系统对所有站点设备的实时监视和远程控制。",
        list: [],
        image: require("../../assets/imgs/settle/wushuichangzikong/wushuichangzikong_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [
          "构建先进、科学、实用的生产运行自控平台。",
          "提升数据采集、报表统计和数据查看等效率。",
          "优化生产运营管理体系，降低运营成本。",
        ],
        image: "",
      },
    },
    {
      title: "智能拉运解决方案",
      time: "",
      p: "适用对象：水务集团/污水厂/排水部门、水泥厂、煤炭企业、冷链运输物流企业、危险化学品经营、使用、储存企业、城建渣土运输公司、集装箱港口码头公司",
      yewutongdian: {
        p: "",
        list: [
          "拉运去向统计繁琐。",
          "拉运量无法准确计量统计。",
          "日常报表、资产等管理工作量大。",
        ],
        image: "",
      },
      fuwuneirong: {
        p: "",
        list: [
          "实时地图掌控：实现对拉运车辆的实时监控，包含车速、载重量、位置等信息，将每日的拉运现状合理地展示出来。",
          "智能告警系统：实现对车辆的各数据监控，提供车速、位置、装卸异常报警，及时通知管理人员做出相应处理。",
          "智能调度系统：分析片区的负责车辆，通过报警等信息及时生成自动任务并通知车辆，减少人工干预，提高容错率。",
          "细化的流向管控：在煤炭、钢铁、矿石等大宗商品的运输过程中，计量车辆的实时操作，解决运输途中的偷货、串货、换货等问题，也可用于短途业务的趟次统计。",
        ],
        image: require("../../assets/imgs/settle/zhinenglayun/zhinenglayun_fuwuneirong.png"),
      },
      fanganjieshao: {
        p: "系统通过自主开发的智能算法对各类生产运行数据进行可视化分析，为客户提供多方位的污水拉运处理服务，能够实现污水池水位监控、智能告警、运营分析、拉运调度预案管理、工单管理等业务，提升企业智能化、精细化、科学化管理水平。",
        list: [],
        image: require("../../assets/imgs/settle/zhinenglayun/zhinenglayun_fanganjieshao.png"),
      },
      fanganjiazhi: {
        p: "",
        list: [
          "构建先进、科学、实用的收运管理平台。",
          "在高精度下给客户提供日、月、年度报表。",
          "有效控制拉运的支出。",
          "提升数据采集、报表统计和数据查看等效率。",
          "优化运营管理体系，降低运营成本。",
        ],
        image: "",
      },
    },
  ];
  const [dataObj, setDataObj] = useState(inWaterData[0]);
  const [clickedIndex, setClickedIndex] = useState(0);
  const toArticle = (arr, index) => {
    setDataObj(arr[index]);
    setClickedIndex(index);
  };

  // 定位
  // const array = [
  //   {
  //     name: ["业务痛点", "服务内容", "方案介绍", "方案价值"],
  //     id: ["tongdian", "fuwuneirong", "fanganjieshao", "fanganjiazhi"],
  //   },
  // ];
  // function scrollToElement(id) {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // }

  const items = [
    {
      key: "1",
      label: "智慧供水解决方案",
      btnArr: inWater,
      articleArr: inWaterData,
    },
    {
      key: "2",
      label: "智慧排水解决方案",
      btnArr: outWater,
      articleArr: outWaterData,
    },
  ];

  const [curItem, setCurItem] = useState(items[0]);
  const onChange = (key) => {
    const item = items.find((item) => item.key === key);
    setCurItem(item);
    toArticle(item.articleArr, 0);
  };

  // 子项按钮
  const BtnChild = () => {
    return (
      <div className="settle_btn_Box">
        {curItem.btnArr.map((v, i) => {
          return (
            <button
              className={`${i === clickedIndex ? "active" : "settle_btn"}`}
              // className="active"
              onClick={() => toArticle(curItem.articleArr, i)}
              key={i}
            >
              <span>{v}</span>
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="solution_main">
      <Banner data={{ text, text_yw, imgUrl }} />

      {/* middle  */}
      <div className="middleBox">
        <div className="middle w95">
          <div
            ref={targetRef}
            className={inView ? "left animationleft" : "left"}
          >
            <Tabs
              defaultActiveKey="1"
              // tabBarExtraContent={operations}
              items={items}
              onChange={onChange}
            />
          </div>

          {/* <div className="left">
            <span
              className={activeSpan === "span1" ? "add spanB" : ""}
              onClick={() => {
                addClassName("span1");
                toArticle(inWaterData, 0);
              }}
            >
              智慧供水解决方案
            </span>
            <span
              className={activeSpan === "span2" ? "add spanB" : ""}
              onClick={() => {
                addClassName("span2");
                toArticle(outWaterData, 0);
              }}
            >
              智慧排水解决方案
            </span>
          </div> */}
          <div
            ref={targetRef}
            className={inView ? "position animationright" : "position"}
          >
            <img
              loading="lazy"
              src={require("../../assets/imgs/client/pos_ico.png")}
              alt=""
            />
            您当前的位置：
            <a href="/">首页</a>
            <span> / {text}</span>
          </div>
        </div>
      </div>
      <div ref={targetRef2} className={inView2 ? "w95 animationInX" : "w95"}>
        <BtnChild />
      </div>

      {/* 标题定位 */}
      {/* <div className="container">
        <div className="location">
          {array[0].name.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => scrollToElement(`box${index}`)}
              >
                {item}
              </button>
            );
          })}
        </div>
      </div> */}

      {/* 解决方案 */}
      <div className="SettleArticle">
        <SettleArticle data={dataObj} />
      </div>

      {/* 底部 */}
      <div
        className="solution_footer"
        // ref={solutionFooterRef}
      >
        <div className="conten">
          <Form />
        </div>
      </div>
    </div>
  );
}
