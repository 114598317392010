import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import "./productsltem_index.scss";
import useInView from '../../../../components/hook/hook'

export default function Productsltem_index() {
  //监听
  const [targetRef, inView] = useInView();
  const [targetRef2, inView2] = useInView();
  // 面包屑

  const breadcrumbNameMap = {
    "/": "首页",
    "/about": "关于巨川",
    "/client": "客户案例",
    "/news": "新闻中心",
    "/products/products_index": "二氧化氯消毒系统",
    "/products": "产品中心",
    "/settle": "解决方案",
  };

  const navigate = useNavigate();
  const onClickMbx = (e) => {
    navigate("/products/products_tow");
    // settitle(e.text)
    sessionStorage.setItem("products_tow", e.text);
  };
  // 循环
  const listData = [
    {
      id: 1,
      text: "二氧化氯消毒系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/消毒2.png"),
    },
    {
      id: 2,
      text: "水处理加药系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/加药2.png"),
    },
    {
      id: 3,
      text: "膜处理系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/膜处理2.png"),
    },
    {
      id: 4,
      text: "生物除臭系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/除臭2.png"),
    },
    {
      id: 5,
      text: "特种离子去除系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/特种离子2.png"),
    },
    {
      id: 6,
      text: "自动化控制系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/自动化2.png"),
    },
    {
      id: 7,
      text: "智慧水务平台系统",
      icoUrl: require("../../../../assets/imgs/product/ioc/智慧水务2.png"),
    },
    {
      id: 8,
      text: "环保水处理工程",
      icoUrl: require("../../../../assets/imgs/product/ioc/工程2.png"),
    },
  ];
  return (
    <div className="products_index">
      <div className="daohang">
        <div ref={targetRef} className={inView ? "bt animationleft":"bt"}>产品中心</div>
        <div ref={targetRef} className={inView ? "mbx animationright":"mbx"}>
          <Breadcrumb separator=">">
            <img
              src={require("../../../../assets/imgs/client/pos_ico.png")}
              alt=""
            />
            <span>您当前位置： </span>
            <Breadcrumb.Item>
              <Link to="/">首页</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/products">产品中心</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* 内容 */}
      <div className="products_main">
        <div ref={targetRef2} className={inView2 ? "content animationup":"content"}>
          <ul>
            {listData.map((item) => {
              return (
                <li
                  key={item.id}
                  id={item.id}
                  className="li"
                  onClick={() => onClickMbx(item)}
                >
                  <img src={item.icoUrl} alt="" className="img" />
                  <span>{item.text}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
