import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Checkbox, Button, Form, Input, Select, Space } from "antd";
import Banner from "../../components/banner/banner";
import useInView from "../../components/hook/hook";
import "./Relation.scss";
const { Option } = Select;
const layout = null;
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
export default function News() {
  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //监听
  const [targetRef, inView] = useInView();
  const [targetRef2, inView2] = useInView();
  const text = "在线留言";
  const text_yw = "CONTACT US";
  const imgUrl = require("../../assets/imgs/news/news_backgrout.jpg");
  // 面包屑
  const breadcrumb = [
    { title: <Link to="/">首页</Link> },
    { title: <Link to="/relation">在线留言</Link> },
  ];
  // 表单
  const [form] = Form.useForm();
  const onGenderChange = (value) => {
    switch (value) {
      case "male":
        form.setFieldsValue({
          note: "Hi, man!",
        });
        break;
      case "female":
        form.setFieldsValue({
          note: "Hi, lady!",
        });
        break;
      case "other":
        form.setFieldsValue({
          note: "Hi there!",
        });
        break;
      default:
    }
  };
  const onFinish = (values) => {
    console.log(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="Relation_main">
      <Banner data={{ text, text_yw, imgUrl }} />
      <div className="daoh">
        <div className="daoh_content">
          <div
            ref={targetRef}
            className={inView ? "gsjs animationleft" : "gsjs"}
          >
            在线留言
          </div>
          <div
            ref={targetRef}
            className={inView ? "mbx animationright" : "mbx"}
          >
            <img src={require("../../assets/imgs/client/pos_ico.png")} alt="" />
            <span>您当前位置： </span>
            <Breadcrumb items={breadcrumb} />
          </div>
        </div>
      </div>
      <div className="Relation_big">
        <div
          ref={targetRef2}
          className={inView2 ? "biaodan_main animationup" : "biaodan_main"}
        >
          <h1>请填写留言，可索取更多产品和解决方案介绍</h1>
          <div className="fg"></div>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout={"vertical"}
            style={{
              maxWidth: "none",
            }}
          >
            <div className="biaodang_conten">
              <div className="left">
                <Form.Item
                  name="username"
                  label="您的姓名"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的名字!",
                    },
                  ]}
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item
                  name="CompanyName"
                  label="公司名称"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的公司名称!",
                    },
                  ]}
                >
                  <Input placeholder="请输入公司名称" />
                </Form.Item>
                <Form.Item
                  name="Email"
                  label="电子邮箱"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的邮箱!",
                    },
                  ]}
                >
                  <Input placeholder="请输入电子邮件" />
                </Form.Item>
              </div>
              <div className="right">
                <Form.Item
                  name="phone"
                  label="您的手机号码"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的手机号!",
                    },
                  ]}
                >
                  <Input placeholder="请输入您的手机号" />
                </Form.Item>
                <Form.Item
                  name="office"
                  label="职务"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的职务!",
                    },
                  ]}
                >
                  <Input placeholder="请输入您的职务" />
                </Form.Item>
                <Form.Item
                  name="products"
                  label="感兴趣的产品"
                  rules={[
                    {
                      required: true,
                      message: "请选择感兴趣的产品!",
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择感兴趣的产品"
                    onChange={onGenderChange}
                    allowClear
                  >
                    <Option value="水处理设备">水处理设备</Option>
                    <Option value="自动化控制系统">自动化控制系统</Option>
                    <Option value="智慧水务">智慧水务</Option>
                    <Option value="其他">其他</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <Form.Item name="message" label="您的留言内容">
              <Input.TextArea placeholder="请输入留言内容" />
            </Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Checkbox>
                我提交我的详细信息并同意{" "}
                <Link className="relation_btn" to="/secrecy">
                  用户个人信息保密协议
                </Link>{" "}
                ，明白我将接收到来自巨川的更多信息。
              </Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("gender") === "other" ? (
                  <Form.Item
                    name="customizeGender"
                    label="Customize Gender"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
