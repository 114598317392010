import React from "react";
import Banner from "../../components/banner/banner";
import "./News.scss";
import { Outlet } from "react-router-dom";
import { Tabs } from "antd";
import useInView from "../../components/hook/hook";

export default function News() {
  const text = "新闻咨讯";
  const text_yw = "NEWS";
  const imgUrl = require("../../assets/imgs/news/news_backgrout.jpg");
  const items = [{ key: "1", label: "新闻资讯" }];
  //监听
  const [targetRef, inView] = useInView();
  return (
    <div className="news_main">
      <Banner data={{ text, text_yw, imgUrl }} />
      {/* middle  */}
      <div className="middleBox">
        <div className="middle w95">
          <div
            ref={targetRef}
            className={inView ? "left animationleft" : "left"}
          >
            <Tabs defaultActiveKey="1" items={items} />
          </div>
          <div
            ref={targetRef}
            className={inView ? "position animationright" : "position"}
          >
            <img src={require("../../assets/imgs/client/pos_ico.png")} alt="" />
            您当前的位置：
            <a href="/">首页</a>
            <a href="/#/news"> / {text}</a>
            {/* <span> / {text}</span> */}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
