import React from "react";
import "./SettleArticle.scss";
import useInView from "../../components/hook/hook";
const SettleSplWater1 = (props) => {
  // 小标题图标
  const t2_Icon = require("../../assets/imgs/settle/tongdian.png");

  const article = props.data;
  //监听
  const [targetRef, inView] = useInView();
  return (
    <div className="box">
      <div
        ref={targetRef}
        className={inView ? "article animationup" : "article"}
      >
        {/* 标题 */}
        <div className="title">
          <h1>{article.title}</h1>
          <div className="time">
            {article.time ? "时间：" + article.time : ""}
          </div>
        </div>
        {/* 简介 */}
        <div className="jianjie">
          <p>{article.p}</p>
        </div>
        {/* 业务痛点 */}
        {Boolean(
          article.yewutongdian.p ||
            article.yewutongdian.image ||
            article.yewutongdian.list.length
        ) && (
          <div className="tongdian" id="tongdian">
            <div className="t2">
              <img loading="lazy" className="icon_t2" src={t2_Icon} alt="" />
              <h3>业务痛点</h3>
            </div>
            <div className="tongdian_text">
              {article.yewutongdian.p && <p>{article.yewutongdian.p}</p>}

              {/* <p
                dangerouslySetInnerHTML={{ __html: article.yewutongdian.p }}
              ></p> */}
              {article.yewutongdian.list && (
                <ul>
                  {article.yewutongdian.list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              )}
              {article.yewutongdian.image && (
                <img
                  className="imgCont"
                  loading="lazy"
                  src={article.yewutongdian.image}
                  alt=""
                />
              )}
            </div>
          </div>
        )}
        {/* 服务内容 */}
        {Boolean(
          article.fuwuneirong.p ||
            article.fuwuneirong.image ||
            article.fuwuneirong.list.length
        ) && (
          <div className="fuwuneirong" id="fuwuneirong">
            <div className="t2">
              <img loading="lazy" className="icon_t2" src={t2_Icon} alt="" />
              <h3>服务内容</h3>
            </div>
            <div className="fuwuneirong_text">
              {article.fuwuneirong.p && <p>{article.fuwuneirong.p}</p>}
              {/* <p
                dangerouslySetInnerHTML={{ __html: article.fuwuneirong.p }}
              ></p> */}
              {article.fuwuneirong.list && (
                <ul>
                  {article.fuwuneirong.list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              )}
              {article.fuwuneirong.image && (
                <img
                  className="imgCont"
                  loading="lazy"
                  src={article.fuwuneirong.image}
                  alt=""
                />
              )}
            </div>
          </div>
        )}
        {/* 方案介绍 */}
        {Boolean(
          article.fanganjieshao.p ||
            article.fanganjieshao.image ||
            article.fanganjieshao.list.length
        ) && (
          <div className="fanganjieshao" id="fanganjieshao">
            <div className="t2">
              <img loading="lazy" className="icon_t2" src={t2_Icon} alt="" />
              <h3>方案介绍</h3>
            </div>
            <div className="fanganjieshao_text">
              {article.fanganjieshao.p && <p>{article.fanganjieshao.p}</p>}
              {/* <p
                dangerouslySetInnerHTML={{ __html: article.fanganjieshao.p }}
              ></p> */}
              {article.fanganjieshao.list && (
                <ul>
                  {article.fanganjieshao.list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              )}
              {article.fanganjieshao.image && (
                <img
                  className="imgCont"
                  loading="lazy"
                  src={article.fanganjieshao.image}
                  alt=""
                />
              )}
            </div>
          </div>
        )}
        {/* 方案价值 */}
        {Boolean(
          article.fanganjiazhi.p ||
            article.fanganjiazhi.image ||
            article.fanganjiazhi.list.length
        ) && (
          <div className="fanganjiazhi" id="fanganjiazhi">
            <div className="t2">
              <img loading="lazy" className="icon_t2" src={t2_Icon} alt="" />
              <h3>方案价值</h3>
            </div>
            <div className="fanganjiazhi_text">
              {article.fanganjiazhi.p && <p>{article.fanganjiazhi.p}</p>}

              {/* <p
                dangerouslySetInnerHTML={{ __html: article.fanganjiazhi.p }}
              ></p> */}
              {article.fanganjiazhi.list && (
                <ul>
                  {article.fanganjiazhi.list.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              )}
              {article.fanganjiazhi.image && (
                <img
                  className="imgCont"
                  loading="lazy"
                  src={article.fanganjiazhi.image}
                  alt=""
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SettleSplWater1;
