import React, { useEffect, useState } from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// 导入 Swiper 和 SwiperSlide 组件
import { Swiper, SwiperSlide } from 'swiper/react';
import useInView from '../../components/hook/hook'
import 'swiper/swiper-bundle.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './About.scss'
const Swiper1 = () => {
    //监听
    const [targetRef, inView] = useInView();
    const [num, setNum] = useState(window.innerWidth > 900 ? 5 : 1)
    const array = [
        {
            id: 1,
            imgUrl: require("../../assets/imgs/about/荣誉资质/发明专利/打码替换-一种高纯二氧化氯的制备装置和制备方法.jpg"),
        },
        {
            id: 2,
            imgUrl: require("../../assets/imgs/about/荣誉资质/发明专利/打码替换一种曝气型二氧化氯发生器.jpg"),
        },
        {
            id: 3,
            imgUrl: require("../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-供热站自动化控制供热系统1.jpg"),
        },
        {
            id: 4,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-模块化污水处理设备.jpg')
        },
        {
            id: 5,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-全自动除臭设备.jpg')
        },
        {
            id: 6,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-全自动加药装置.jpg')
        },
        {
            id: 7,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-外观设计专利.jpg')
        },
        {
            id: 8,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-一种带有振动器的全自动加药装置.jpg')
        },
        {
            id: 9,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-一种二氧化氯发生器供水系统96号.jpg')
        },
        {
            id: 10,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-一种二氧化氯反应装置.jpg')
        },
        {
            id: 11,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-一种曝气型二氧化氯发生器.jpg')
        },
        {
            id: 12,
            imgUrl: require('../../assets/imgs/about/荣誉资质/实用新型_外观专利/打码替换-一种设有加热带的全自动加药装置.jpg')
        },
    ]
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1024 && window.innerWidth >= 900) {
                setNum(3)
            } else if (window.innerWidth < 900) {
                setNum(1)
            } else {
                setNum(5)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div ref={targetRef} className={inView ? "Swiper1 animationInX" : 'Swiper1'}>
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                slidesPerView={num}
                spaceBetween={60}
                centeredSlides={true}
                loop={true}
                observer={true}//修改swiper自己或子元素时，自动初始化swiper
                observeParents={true}//修改swiper的父元素时，自动初始化swiper
                freeMode={true}//切换反弹
                autoplay={{
                    delay: 1000,
                }}
                pagination={{
                    clickable: true
                }}
            >
                <div class="swiper-wrapper">
                    {array.map((item, index) => (
                        <SwiperSlide
                            key={index}
                        >
                            <img src={item.imgUrl} alt="" />
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
        </div>

    )
}
export default Swiper1