import React, { useState, useEffect, useRef } from "react";
import "./CaseFirstSwiper.scss";

const ThumbsGalleryLoop = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const thumbsRef = useRef(null);

  useEffect(() => {
    setActiveIndex(0);
  }, [images]);

  useEffect(() => {
    if (thumbsRef.current) {
      const thumbWidth = thumbsRef.current.children[activeIndex].offsetWidth;
      const thumbScrollLeft =
        thumbsRef.current.children[activeIndex].offsetLeft;
      const containerWidth = thumbsRef.current.offsetWidth;

      if (thumbScrollLeft < thumbsRef.current.scrollLeft) {
        thumbsRef.current.scrollTo({
          left: thumbScrollLeft,
          behavior: "smooth",
        });
      } else if (
        thumbScrollLeft + thumbWidth >
        thumbsRef.current.scrollLeft + containerWidth
      ) {
        thumbsRef.current.scrollTo({
          left: thumbScrollLeft - containerWidth + thumbWidth,
          behavior: "smooth",
        });
      }
    }
  }, [activeIndex]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    scrollToThumbnail(activeIndex);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    scrollToThumbnail(activeIndex);
  };

  const scrollToThumbnail = (index) => {
    if (thumbsRef.current) {
      const thumbWidth = thumbsRef.current.children[index].offsetWidth;
      const thumbScrollLeft = thumbsRef.current.children[index].offsetLeft;
      const containerWidth = thumbsRef.current.offsetWidth;

      if (thumbScrollLeft < thumbsRef.current.scrollLeft) {
        thumbsRef.current.scrollTo({
          left: thumbScrollLeft,
          behavior: "smooth",
        });
      } else if (
        thumbScrollLeft + thumbWidth >
        thumbsRef.current.scrollLeft + containerWidth
      ) {
        thumbsRef.current.scrollTo({
          left: thumbScrollLeft - containerWidth + thumbWidth,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div className="gallery_container">
      <div className="main_image">
        <img
          loading="lazy"
          className="galleryImg"
          src={images[activeIndex]}
          alt={`Image ${activeIndex + 1}`}
        />

        <button className="arrow prev" onClick={handlePrev}>
          &#10094;
        </button>
        <button className="arrow next" onClick={handleNext}>
          &#10095;
        </button>
      </div>
      <div className="thumbs" ref={thumbsRef}>
        {images.map((image, index) => (
          <img
            loading="lazy"
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className={index === activeIndex ? "active" : ""}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
export default ThumbsGalleryLoop;

// import React, { useState, useEffect, useRef } from "react";
// import "./CaseFirstSwiper.scss";

// const ThumbsGalleryLoop = ({ images }) => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const thumbsRef = useRef(null);

//   useEffect(() => {
//     setActiveIndex(0);
//   }, [images]);
//   useEffect(() => {
//     console.log(activeIndex != -1);
//     // 当 activeIndex 改变时，滚动到当前活动缩略图的位置
//     if (thumbsRef.current) {
//       thumbsRef.current.children[activeIndex].scrollIntoView({
//         behavior: "smooth",
//         block: "nearest",
//         inline: "center",
//       });
//     }
//   }, [activeIndex]);

//   const handleClick = (index) => {
//     setActiveIndex(index);
//   };

//   const handlePrev = () => {
//     setActiveIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setActiveIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   return (
//     <div className="gallery_container">
//       <div className="main_image">
//         <img
//           className="galletyImg"
//           // className={`galletyImg ${activeIndex != -1 ? "hidden" : ""}`}
//           src={images[activeIndex]}
//           alt={`Image ${activeIndex + 1}`}
//         />

//         <button className="arrow prev" onClick={handlePrev}>
//           &#10094;
//         </button>
//         <button className="arrow next" onClick={handleNext}>
//           &#10095;
//         </button>
//       </div>
//       <div className="thumbs" ref={thumbsRef}>
//         {images.map((image, index) => (
//           <img
//             key={index}
//             src={image}
//             alt={`Thumbnail ${index + 1}`}
//             className={index === activeIndex ? "active" : ""}
//             onClick={() => handleClick(index)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ThumbsGalleryLoop;
