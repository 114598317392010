import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Button, Checkbox, Form, Input,Select } from "antd";
import Map from './MapContainer/MapContainer'
import "./Form.scss";
const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const { Option } = Select;
const Formdiv = () => {

   // 表单
   const [form] = Form.useForm();
  const onGenderChange = (value) => {
    switch (value) {
      case "male":
        form.setFieldsValue({
          note: "Hi, man!",
        });
        break;
      case "female":
        form.setFieldsValue({
          note: "Hi, lady!",
        });
        break;
      case "other":
        form.setFieldsValue({
          note: "Hi there!",
        });
        break;
      default:
    }
  };
  const layout = null;
  const [show, setshow] = useState(window.innerWidth >= 1024)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setshow(false);
      } else {
        setshow(true);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[])
  return (
    <div style={{ with: "100%", display: "block" }} id="form_mai">
      <h2>请填写留言，可获取更多产品和解决方案介绍</h2>
      <div className="content">
        <div className="item left">
          <Form
            name="basic"
            {...layout}
            style={{
              maxWidth: "none",
            }}
            layout={"vertical"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="left_item">
              <div className="left_item1 left_item2 ">
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的名字!",
                    },
                  ]}
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item
                  name="CompanyName"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的公司名称!",
                    },
                  ]}
                >
                  <Input placeholder="请输入公司名称" />
                </Form.Item>
                <Form.Item
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的邮箱!",
                    },
                  ]}
                >
                  <Input placeholder="请输入电子邮件" />
                </Form.Item>
              </div>
              <div className="left_item1">
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的手机号!",
                    },
                  ]}
                >
                  <Input placeholder="请输入您的手机号" />
                </Form.Item>
                <Form.Item
                  name="office"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的职务!",
                    },
                  ]}
                >
                  <Input placeholder="请输入您的职务" />
                </Form.Item>
                <Form.Item
                  name="products"
                  rules={[
                    {
                      required: true,
                      message: "请选择感兴趣的产品!",
                    },
                  ]}
                >
                  
                  <Select
                    placeholder="请选择感兴趣的产品"
                    onChange={onGenderChange}
                    style={{fontSize:'inherit'}}
                    allowClear
                  >
                    <Option value="水处理设备">水处理设备</Option>
                    <Option value="自动化控制系统">自动化控制系统</Option>
                    <Option value="智慧水务">智慧水务</Option>
                    <Option value="其他">其他</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="message"
            >
              <Input.TextArea
                rows={4}
                placeholder="请输入留言内容"
                maxLength={6}
              />
            </Form.Item>

            <div className="formbtn">
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Checkbox>
                我提交我的详细信息并同意{" "}
                <Link className="relation_btn" to="/secrecy">
                  用户个人信息保密协议
                </Link>{" "}
                ，明白我将接收到来自巨川的更多信息。
              </Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                style={{
                  maxWidth: 800,
                  maxWidth: "100%",
                  marginInlineStart: "0%",
                }}
              >
                <Button type="primary" htmlType="submit">
                  确认
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="item right map">
          {show?<Map />: null
          }
        </div>
      </div>
    </div>
  );
};
export default Formdiv;
