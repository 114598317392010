import React, { useState, useRef, useEffect } from "react";
import "./NewsDetails.scss";
import NewsDetails1 from "./newsActicle/NewsDetails1";
import NewsDetails2 from "./newsActicle/NewsDetails2";
import NewsDetails3 from "./newsActicle/NewsDetails3";
import NewsDetails4 from "./newsActicle/NewsDetails4";
import useInView from "../../components/hook/hook";
import { useLocation } from "react-router-dom";
import _ from "lodash";

const NewsDetails = () => {
  const location = useLocation();
  const state = _.get(location, "state");
  // const [newsArr, SetNewsArr] = useState(
  //   JSON.parse(sessionStorage.getItem("news_details"))
  // );
  // const newsData = JSON.parse(sessionStorage.getItem("newsData"));
  const [newsArr, SetNewsArr] = useState(state.news_details);
  const newsData = state.newsData;
  const [curNews, SetCurNews] = useState(newsArr[1]);

  // 点击上一篇、下一篇
  const clickPreAft = (id) => {
    const dataIndex = newsData.findIndex((dataItem) => dataItem.id === id);
    SetNewsArr([
      newsData[dataIndex - 1],
      newsData[dataIndex],
      newsData[dataIndex + 1],
    ]);
    SetCurNews(newsData[dataIndex]);
  };

  // 滚动到标题
  const newsTitleRef = useRef(null);
  const handleItemClick = () => {
    if (newsTitleRef.current) {
      newsTitleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  //监听
  const [targetRef, inView] = useInView();
  useEffect(() => {
    // handleItemClick(); // curNews 变化时执行 handleItemClick 函数

    window.scrollTo(0, 500);
  }, [curNews]); // 当 curNews 发生变化时执行 useEffect

  return (
    <div className="newsdetails_main" ref={newsTitleRef}>
      <div className="newsdetail_box">
        <div className="newsdetails">
          {Number(curNews.id) === 1 ? (
            <NewsDetails1 />
          ) : Number(curNews.id) === 2 ? (
            <NewsDetails2 />
          ) : Number(curNews.id) === 3 ? (
            <NewsDetails3 />
          ) : (
            <NewsDetails4 />
          )}
        </div>
        <div
          ref={targetRef}
          className={inView ? "pre_aft animationleft" : "pre_aft"}
        >
          <div
            className="pre"
            onClick={() => {
              if (newsArr[0]) {
                clickPreAft(newsArr[0].id);
              }
            }}
          >
            {newsArr[0] && (
              <>
                <img
                  loading="lazy"
                  src={require("../../assets/imgs/news/pre.png")}
                  alt=""
                />
                <span className="prespan">上一篇</span>
                <span className="title">
                  {newsArr[0] ? newsArr[0].title : ""}
                </span>
              </>
            )}
          </div>
          {newsArr[2] && (
            <div
              className="aft"
              onClick={() => {
                clickPreAft(newsArr[2].id);
              }}
            >
              <span className="title">{newsArr[2].title}</span>
              <span className="aftspan">下一篇</span>
              <img
                loading="lazy"
                src={require("../../assets/imgs/news/aft.png")}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NewsDetails;
