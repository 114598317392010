import { useEffect } from "react";
import styles from "./MapContainer.scss";
import AMapLoader from "@amap/amap-jsapi-loader";

export default function MapContainer() {
    let map = null;
    useEffect(() => {
        AMapLoader.load({
            key: "4df559b322c3bdf19fa017a9441e9852", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
            .then((AMap) => {
                map = new AMap.Map("container", {
                    // 设置地图容器id
                    viewMode: "3D", // 是否为3D地图模式
                    zoom: 15, // 初始化地图级别
                    center: [119.998, 36.054], // 初始化地图中心点位置
                });
                // 创建一个 Marker 实例：
                var marker = new AMap.Marker({
                    position: new AMap.LngLat(119.9985, 36.0535),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                    title: '青岛巨川',
                });

                // 将创建的点标记添加到已有的地图实例：
                map.add(marker);
                //构造矢量圆形
                var circle = new AMap.Circle({
                    center: new AMap.LngLat("119.9985", "36.0535"), //圆心位置
                    radius: 100, //半径 单位:米
                    strokeColor: "#F33", //线颜色
                    strokeOpacity: 1, //线透明度
                    strokeWeight: 3, //线粗细度
                    fillColor: "#ee2200", //填充颜色
                    fillOpacity: 0.35, //填充透明度
                });
                //单独将点标记和矢量圆形添加到地图上
                map.add(circle);
                // map.add(marker);

            })
            .catch((e) => {
                console.log(e);
            });

        return () => {
            map?.destroy();
        };
    }, []);

    return (
        <div
            id="container"
            className={styles.container}
            style={{ height: "100%" }}
        >

        </div>
    );
}