import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import useInView from '../../components/hook/hook'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import _ from "lodash";
import "./CaseSwiper.scss";
// import required modules
import { EffectCoverflow, Autoplay } from "swiper/modules";

export default function App({ handleItemClick, array }) {
  // 跳转至详情页
  const navigate = useNavigate();
  const toCaseDetails = (item) => {
    navigate("/client/case_details", {
      state: { case_details: item, case_array: array },
    });
  };
   //监听
   const [targetRef, inView] = useInView();
  return (
    <>
      <div ref={targetRef} className={inView ? "more animationleft":"more"}>
        <p>更多案例</p>
      </div>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true} // 启用循环模式
        coverflowEffect={{
          rotate: 42,
          // stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        speed={2000} // 控制滑动速度，值越小滑动越平滑
        autoplay={{
          delay: 1000, // 自动播放间隔时间（单位：毫秒）
          disableOnInteraction: false, // 用户交互时是否停止自动播放
        }}
        pagination={true}
        modules={[EffectCoverflow, Autoplay]}
        ref={targetRef} className={inView ? "mySwiper animationright":"mySwiper"}
      >
        {array.map((item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => {
              toCaseDetails(item);
              handleItemClick();
            }}
          >
            <img loading="lazy" src={item.imgUrl} alt="" />
            <h4>{item.title}</h4>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
