import React from 'react'

import './Not404.scss'

export default function Not404() {
    return (
        <div className='Not404'>
           
        </div>
    )
}