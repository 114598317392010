//产品中心
import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import FORMdox from "../../components/Form";
import { Outlet } from "react-router-dom";
import "./Products.scss";
import useInView from "../../components/hook/hook";
export default function Products() {
  //监听
  const [targetRef, inView] = useInView();

  const text = "产品中心";
  const text_yw = "PRODUCTS";
  const imgUrl = require("../../assets/imgs/product/lunbo_background.jpg");
  // 面包屑

  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="products">
      <Banner data={{ text, text_yw, imgUrl }} />
      <Outlet></Outlet>
      <div ref={targetRef} className={inView ? "biaod animationup" : "biaod"}>
        <FORMdox />
      </div>
    </div>
  );
}
