import React from "react";
import "./banner.scss";
import useInView from '../hook/hook'
const Banner = ({ data }) => {
    //监听
    const [targetRef, inView] = useInView();
  return (
    <div className="banner_body">
      <div className="lunbo">
        <div ref={targetRef} className={inView ? "text animationleft":"text"}>
          <div className="text1">{data.text_yw}</div>
          <h1>{data.text}</h1>
          <div className="text2">——</div>
        </div>
      </div>
      <img src={data.imgUrl} alt="" />
    </div>
  );
};
export default Banner;
