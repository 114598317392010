import React from "react";
import "./NewsDetails1.scss";
import useInView from '../../../components/hook/hook'
const NewsDetails = () => {
  const importTextColor = "blue";
      //监听
      const [targetRef, inView] = useInView();
      const [targetRef1, inView1] = useInView();
      const [targetRef2, inView2] = useInView();
  return (
    <div className="newsdetails1_main">
      <div className="newsdetail_box">
        {/* 头部 */}
        <div ref={targetRef} className={inView ? "news1_top animationup":"news1_top"}>
          <h1>
            重磅！《生活饮用水卫生标准》（GB5749-2022）正式发布！加强对消毒副产物指标的严格控制，确保饮用水供水安全！
          </h1>

          <p>
            2022年3月15日发布的《生活饮用水卫生标准》（GB
            5749-2022），已于2023年4月1日正式实施。较前标准GB
            5749-2006，《生活饮用水卫生标准》（GB5749-2022），加强对消毒副产物指标的严格控制，确保饮用水供水安全。
          </p>
          <p style={{ color: importTextColor }}>
            调整指标分类，将一氯二溴甲烷、二氯一溴甲烷、三溴甲烷、二氯乙酸、三氯乙酸和三卤甲烷等
            6 项调整为常规指标。
          </p>
          <p>
            饮用水中的这些物质主要来源是氯化消毒过程中消毒剂与水体中有机物发生反应而形成的副产物，
            <span style={{ color: importTextColor }}>
              人群长期暴露于上述物质产生的健康风险包括致癌性、遗传毒性、
              生殖毒性和发育毒性等。
            </span>
            且我国多部门的水质监测、检测和调查结果表明，一氯二溴甲烷、二氯一溴甲烷、三溴甲烷、二氯乙酸、三氯乙酸和三卤甲烷等
            6 项指标在饮用水中检出情况相对较为普遍，检出率超过
            60%，一氯二溴甲烷和二氯一溴甲烷更是高达
            90%以上。因此在新国标将其调整为常规指标，进一步严格了对消毒副产物指标的控制。
          </p>
          <p>
            《生活饮用水卫生标准》（GB5749-2022）中，水处理工艺流程中预氧化或消毒方式规定：
          </p>
          <p>
            采用
            <span style={{ color: importTextColor }}>液氯、次氯酸钙及氯胺</span>
            时应测定三氯甲烷、一氯二溴甲烷、二氯一溴甲烷、三溴甲烷、三卤甲烷、二氯乙酸、三氯乙酸；
          </p>
          <p>
            采用<span style={{ color: importTextColor }}>次氯酸钠</span>
            时，应测定三氯甲烷、一氯二溴甲烷、二氯一溴甲烷、三溴甲烷、三卤甲烷、二氯乙酸、三氯乙酸、氯酸盐；
          </p>
          <p>
            采用<span style={{ color: importTextColor }}>臭氧</span>
            时应测定溴酸盐；
          </p>
          <p>
            采用<span style={{ color: importTextColor }}>二氧化氯</span>
            时应测定亚氯酸盐；
          </p>
          <p>
            采用
            <span style={{ color: importTextColor }}>
              二氧化氯与氯混合消毒剂发生器
            </span>
            时还应测定亚氯酸盐、氯酸盐、三氯甲烷、一氯二溴甲烷、二氯一溴甲烷、三溴甲烷、三卤甲烷、二氯乙酸、三氯乙酸。
          </p>
          <p style={{ color: importTextColor }}>
            当原水中含有上述污染物，可能导致出厂水和末梢水的超标风险时，无论采用何种预氧化或消毒方式，都应对其进行测定。
          </p>
        </div>
        {/* 中间 */}
        <div className="news1_middle">
          <div ref={targetRef1} className={inView1 ? "imgBox animationleft":"imgBox"}>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/饮用水标准/1.png")}
              alt=""
            />
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/饮用水标准/3.png")}
              alt=""
            />
          </div>

          <img
            loading="lazy"
            className="shuImg"
            src={require("../../../assets/imgs/news/饮用水标准/2.png")}
            alt=""
          />
          <div className="t2">
            <p>
              <span style={{ color: importTextColor }}>调整指标限值</span>
              ，鉴于氯消毒会产生大量的消毒副产物，且部分消毒副产物具有有害的健康效应，因此在控制消毒效果的基础上应尽量减少消毒副产物的产生，避免消毒剂的过量投加是控制消毒副产物的有效方式之一，
              <span style={{ color: importTextColor }}>
                新国标将出厂水中游离氯余量的上限值从 4 mg/L 调整为 2 mg/L。
              </span>
            </p>

            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/饮用水标准/4.png")}
              alt=""
            />

            <div className=" imgBox">
              <img
                loading="lazy"
                className="shuImg"
                src={require("../../../assets/imgs/news/饮用水标准/5.jpg")}
                alt=""
              />
              <img
                loading="lazy"
                className="shuImg"
                src={require("../../../assets/imgs/news/饮用水标准/6.jpg")}
                alt=""
              />
              <img
                loading="lazy"
                className="shuImg"
                src={require("../../../assets/imgs/news/饮用水标准/7.jpg")}
                alt=""
              />
              <img
                loading="lazy"
                className="shuImg"
                src={require("../../../assets/imgs/news/饮用水标准/8.jpg")}
                alt=""
              />
              <img
                loading="lazy"
                className="shuImg"
                src={require("../../../assets/imgs/news/饮用水标准/9.jpg")}
                alt=""
              />
              <img
                loading="lazy"
                className="shuImg"
                src={require("../../../assets/imgs/news/饮用水标准/10.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetails;
