import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Flex, Carousel } from "antd";
import ParticleAnimation from "../../components/ParticleAnimation";
import Formdiv from "../../components/Form";
import Solution from "../../components/SOLUTION";
import Solution2 from "../../components/SOLUTION2";
import useInView from "../../components/hook/hook";
import { data } from "../news/NewsList";
import { array } from "../client/Clientcase";
import { useNavigate } from "react-router-dom";

import "./First.scss";

export default function First() {
  //监听
  const [targetRef, inView] = useInView();
  const [targetRef1, inView1] = useInView();
  const [targetRef2, inView2] = useInView();
  const [targetRef3, inView3] = useInView();
  const [targetRef4, inView4] = useInView();
  const [targetRef5, inView5] = useInView();
  const [targetRef6, inView6] = useInView();
  const [targetRef7, inView7] = useInView();
  //选项卡
  const [activeTab2, setActiveTab2] = useState("tab1");

  const handleTabClick2 = (tab) => {
    setActiveTab2(tab);
  };
  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //新闻

  // 跳转至新闻详情页
  const navigate = useNavigate();
  const toNewDetailsId = (id) => {
    const dataIndex = data.findIndex((dataItem) => dataItem.id === id);
    const newsArr = [data[dataIndex - 1], data[dataIndex], data[dataIndex + 1]];
    navigate("/news/news_details", {
      state: { news_details: newsArr, newsData: data },
    });
  };
  //跳转客户案例详情
  const toCaseDetailId = (id) => {
    debugger;
    const dataIndex = array.findIndex((item) => item.id === id);
    const item = array[dataIndex];
    navigate("/client/case_details", {
      state: { case_details: item, case_array: array },
    });
  };
  // 客户案例
  const caselist=[
    {
      url:require('../../assets/imgs/first_imgs/尚义县大青沟.jpg'),
      title:'尚义县大青沟'
    },
    {
      url:require('../../assets/imgs/first_imgs/莘州水厂.jpg'),
      title:'莘州水厂'
    },
    {
      url:require('../../assets/imgs/first_imgs/农村污水.jpg'),
      title:'农村污水'
    }
  ]
  return (
    <div className="first">
      <Carousel effect="fade" autoplay infinite>
        <div className="slidshow3">
          <img src={require('../../assets//imgs/first_imgs/banner首图1.jpg')} alt="" />
          
        </div>
        <div className="slidshow1">
          <img src={require('../../assets/imgs/first_imgs/banner荣誉\ 2.jpg')} alt="" />
          
        </div>
        <div className="slidshow2">
          <img src={require('../../assets/imgs/first_imgs/banner服务3.jpg')} alt="" />
          
        </div>
      </Carousel>
      {/* 关于我们 */}
      <div ref={targetRef3} className={inView3 ? "about animationup" : "about"}>
        <h1 className="title">关于我们</h1>
        <div className="title2">
          <span className="ico"></span>ABOUT US
        </div>
        <div className="content">
          {/* <div className="viod">
            <img
              src={require("../../assets/imgs/first_imgs/about.jpg")}
              alt=""
            />
            <div className="viod_1">
              <div className="viod_2"></div>
            </div>
          </div> */}
          <div className="explain">
            <h1>掌握核心科技，打造行业品牌</h1>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              公司成立于2008年，坐落在被誉为“品牌之都”的海滨城市—青岛，是一家专业从事水处理设备的研发与生产，智慧系统的开发与建设，环保工程的设计、建设与运营的综合性服务制造商。为客户提供自动化控制系统、智慧水务平台、水质预警监测系统、加氯加药系统、水中特种离子去除工艺等多方位、专业化解决方案。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司拥有自主知识产权、多项专利、计算机软著以及电子与智能化工程、水污染治理等专项资质，并独立研发生产二氧化氯发生器、全自动加药设备、生物除臭设备、应用膜处理设备、活性炭投加装置、离子交换设备、污水处理一体化和中水回用设备等环保水处理设备，广泛应用于饮用水、污水、化工循环水等污染防治领域，被全国化工标准物质委员会授予为“全国二氧化氯专业委员会副理事长单位”、“全国二氧化氯研发基地”，是集科研开发、设计、生产、销售和售后服务于一体的高新技术企业。
            </p>
            <div className="btnbig">
              <Link className="btn" to="/about">
                了解更多 <ArrowRightOutlined style={{ fontSize: "14px" }} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* 解决方案 */}
      <div
        ref={targetRef}
        className={inView ? "figure_1 animationup" : "figure_1"}
      >
        <div className="big_content">
          {/* <Carousel autoplay infinite> */}
          <Carousel style={{ height: "76.5vmin" }} dotPosition="right" autoplay>
            
            <Solution />
            <Solution2 />
          </Carousel>
        </div>
      </div>

      {/* 产品中心 */}
      <div
        ref={targetRef1}
        className={inView1 ? "product animationup" : "product"}
      >
        <div className="title">产品中心</div>
        <div className="title2">
          <span className="ico"></span>PRODUCT CENTER
        </div>
        <div className="conten">
          <div className="tab-buttons">
            <div
              onClick={() => handleTabClick2("tab1")}
              className={activeTab2 === "tab1" ? "active" : ""}
            >
              二氧化氯消毒系统
            </div>
            <div
              onClick={() => handleTabClick2("tab2")}
              className={activeTab2 === "tab2" ? "active" : ""}
            >
              水处理加药系统
            </div>
            <div
              onClick={() => handleTabClick2("tab3")}
              className={activeTab2 === "tab3" ? "active" : ""}
            >
              膜处理系统
            </div>
            <div
              onClick={() => handleTabClick2("tab4")}
              className={activeTab2 === "tab4" ? "active" : ""}
            >
              生物除臭系统
            </div>
            <div
              onClick={() => handleTabClick2("tab5")}
              className={activeTab2 === "tab5" ? "active" : ""}
            >
              特种离子去除系统
            </div>
            <div
              onClick={() => handleTabClick2("tab6")}
              className={activeTab2 === "tab6" ? "active" : ""}
            >
              自动化控制系统
            </div>
            <div
              onClick={() => handleTabClick2("tab7")}
              className={activeTab2 === "tab7" ? "active" : ""}
            >
              智慧水务平台系统
            </div>
            <div
              onClick={() => handleTabClick2("tab8")}
              className={activeTab2 === "tab8" ? "active" : ""}
            >
              环保水处理工程
            </div>
          </div>
          <div className="tab-content">
            {activeTab2 === "tab1" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">二氧化氯消毒系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;JCT型二氧化氯发生器拥有2项发明专利，专利号：1、（ZL
                      201310458446.4）大型高纯二氧化氯发生装置及发生方法，2、（ZL
                      201510613836.3）一种曝气型二氧化氯发生器，由全国化学标准物质委员会二氧化氯专业委员会监制，是向全国杀菌灭藻工况场合、饮用水消毒企业推荐与推广的产品。技术获得中华人民共和国教育部科学进步奖一等奖，华夏建设科学技术奖二等奖。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/二氧化氯发生器.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab2" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">水处理加药系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;全自动加药装置集自动、连续、一体化的药剂溶配及投药，本装置由溶解式箱体、干粉投加系统、溶液搅拌系统、控制系统、液体投加系统构成。干粉药剂从料斗下部的螺旋推进器进入预混器与清水进行预混，被湿润的物料进入配制槽进行稀释混合，按用户要求浓度在线进行配制。本装置具有自动化程度高、性能稳定、工作可靠、菜单操作简单等特点。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/全自动加药1.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab3" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">膜处理系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;超滤（简称UF）是利用纳米级的物理孔径在一定的压力作用下，对料液中的物质进行分离、净化、纯化和浓缩的纯物理过程，它不引起分离物质化学性质的变化，是一种安全和可靠的过滤和浓缩方法，可有效去除水中的悬浮物、胶体、色度、浊度、有机物、细菌和大肠杆菌等杂质；PVDF超滤膜组件根据膜丝的安装形式可分为浸没式超滤膜组件和柱式超滤膜组件。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/浸没式超滤膜  (1).jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab4" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">生物除臭系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;生物除臭是采用生物法，通过专门培养在生物滤池内生物填料上的微生物膜对废臭气分子进行除臭的生物废气处理技术。当含有气、液、固三项混合的有毒、有害、有恶臭的废气经收集管道导入本系统后通过培养生长在生物填料上的微生物菌株形成的生物膜来净化和降解废气中的污染物。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/生物除臭1.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab5" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">特种离子去除系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;特种离子去除是指将矿井水、自然水体、市政供水及其他相应水相体系中的氯离子、硝酸根离子、氟离子、砷离子、汞离子、三价铬离子、硬度等污染物指标离子通过选择性吸附、交换、氧化、还原等方法进行深度去除，从而保证水相体系正常使用功能。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/tab_content3.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab6" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">自动化控制系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着经济的发展和社会进步，城市给水行业遇到了严重的挑战。对水处理厂而言，符合标准的原水日益缺乏，而人们对水质的要求不断提高。这就导致水厂的处理工艺不断改进，并对水厂自动控制系统提出更高的要求。为满足水处理工艺优化控制的需要，水厂自动化控制系统的功能已从当初简单的数据监测发展到今天的先进控制。当今，一个现代化水厂自动化控制系统的组成结构、功能和性能要求、各种控制策略及其发展方向等方面的问题，越来越受到同行们的关注。水厂实现自动化的根本目的是提高生产的可靠性和安全性，实现优质、低耗和高效供水，获得良好的经济效益和社会效益。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/大场水厂.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab7" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">智慧水务平台系统</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智慧水务是利用物联网、大数据、云计算、移动互联网等技术，远程在线实时获得整个水务系统（取水、制水、供水、排水）的运行状态信息，将生产SCADA、GIS、水质预警监测、二供系统、安防门禁、工单系统、水文气象信息、手机APP等系统应用集成在一起，实现“跨专业、跨系统”的数据共享、融合和分析，打破传统的单个系统的应用模式，消除信息孤岛，实现“信息全面感知、数据综合分析、高度智慧决策”的应用模式。
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/大场数字化水厂.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab2 === "tab8" && (
              <div className="tab">
                <div className="conten_2">
                  <div className="left">
                    <h3 className="h">环保水处理工程</h3>
                    <p className="p">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;净污水厂自控工程及工艺设备总包等
                    </p>
                    <div>
                      <Link className="btn" to="/products">
                        了解更多{" "}
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </Link>
                    </div>
                  </div>
                  <div className="right">
                    <img
                      src={require("../../assets/imgs/first_imgs/环保水处理工程_img.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 面向水务行业数字化完整解决方案 */}
      <div ref={targetRef2} className={inView2 ? "face animationInX" : "face"}>
        <div className="conten">
          <h2 className="h2">信誉至高无上 售后一诺千金</h2>
          <div className="btn" onClick={() => navigate("/relation")}>
            联系我们 <ArrowRightOutlined />
          </div>
        </div>
      </div>
      {/* 客户案例 */}
      <div ref={targetRef4} className={inView4 ? "case animationup" : "case"}>
        <div className="title">客户案例</div>
        <div className="title2">
          <span className="ico"></span>CUSTOMER STORIES
        </div>
        <div className="content">
          {
            caselist.map((item)=>{
              return (
                <div className="item item1" onClick={() => toCaseDetailId(2)}>
            <div className="imgdiv">
              <img src={item.url} alt="" />
            </div>
            <div className="item_sm">{item.title}</div>
          </div>
              )
            })
          }
          {/* <div className="item item1" onClick={() => toCaseDetailId(2)}>
            <div>
              <img src={require('../../assets/imgs/first_imgs/kehu/禹城润禹水务三水厂.jpg')} alt="" />
            </div>
            <div className="item_sm">禹城润禹水务三水厂</div>
          </div>
          <div className="item item2" onClick={() => toCaseDetailId(5)}>
            <div className="item_sm">沧州渤海新区中捷水厂</div>
          </div>
          <div className="item item3" onClick={() => toCaseDetailId(13)}>
            <div className="item_sm">聊城市莘州水厂</div>
          </div> */}
        </div>
        <div className="footer">
          <Link className="btn" to="/client">
            更多案例 <ArrowRightOutlined />
          </Link>
        </div>
      </div>
      {/* 新闻中心 */}
      <div ref={targetRef5} className={inView5 ? "news animationup" : "news"}>
        <div className="title">新闻中心</div>
        <div className="title2">
          <span className="ico"></span>NEWS CENTER
        </div>
        <div className="content">
          <div
            ref={targetRef5}
            className={
              inView5 ? "left content_item animationleft" : "left content_item"
            }
          >
            <div className="img">
              <img src={require("../../assets/imgs/news/农污/1.png")} alt="" />
            </div>
            <div className="text">
              <div className="time">{"2023年2月28"}</div>
              <div className="sm">
                <span className="text1">
                  青岛西海岸农村生活污水智慧平台：开启农村生活污水治理新模式！
                </span>
                <Flex gap="small" vertical>
                  <Flex wrap="wrap" gap="small">
                    <Button
                      className="news_left_xt"
                      type="primary"
                      shape="circle"
                      icon={<ArrowRightOutlined />}
                      onClick={() => toNewDetailsId(2)}
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
          <div
            ref={targetRef5}
            className={
              inView5
                ? "right content_item animationright"
                : "right content_item"
            }
          >
            <div className="right_item">
              <div className="lef">
                <div className="time news_xt">{"2023-05"}</div>
                <div className="num news_xt">30</div>
              </div>
              <div className="cont">
                <h4>
                  焕新前行 共赴未来——巨川智能环保水处理产业园启动仪式成功举办！
                </h4>
                <p className="news_xt">
                  "孟夏草长，花开五月，巨川智能环保水处理产业园启动仪式以“焕新前行
                  共赴未来”为主题，于2023年5月21日顺利举办！",
                </p>
              </div>
              <div className="rig">
                <Flex gap="small" vertical>
                  <Flex wrap="wrap" gap="small">
                    <Button
                      className="news_xt"
                      type="primary"
                      shape="circle"
                      icon={<ArrowRightOutlined />}
                      onClick={() => toNewDetailsId(1)}
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
            <div className="right_item">
              <div className="lef">
                <div className="time news_xt">{"2023-7"}</div>
                <div className="num news_xt">30</div>
              </div>
              <div className="cont">
                <h4>
                  青岛西海岸新区：深入开展农村供水水质提升行动，农村供水安全有保障！
                </h4>
                <p className="news_xt">
                  随着我国农村经济的不断发展，农村饮用水安全问题逐渐引起人们的广泛关注，从“喝上水”到“喝好水”，已成为人们的殷切期望。青岛作为北方资源性缺水城市，连续多年将农村饮水安全列入市办实事，推动实施多项农村供水提标升级项目，青岛供水安全保障能力有了巨大提升。...
                </p>
              </div>
              <div className="rig">
                <Flex gap="small" vertical>
                  <Flex wrap="wrap" gap="small">
                    <Button
                      className="news_xt"
                      type="primary"
                      shape="circle"
                      icon={<ArrowRightOutlined />}
                      onClick={() => toNewDetailsId(3)}
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
            <div className="right_item">
              <div className="lef">
                <div className="time news_xt">{"2023-08"}</div>
                <div className="num news_xt">30</div>
              </div>
              <div className="cont">
                <h4>
                  重磅！《生活饮用水卫生标准》（GB5749-2022）正式发布！加强对消毒副产物指标的严格控制，确保饮用水供水安全！
                </h4>
                <p className="news_xt">
                  2022年3月15日发布的《生活饮用水卫生标准》（GB
                  5749-2022），已于2023年4月1日正式实施。较前标准GB
                  5749-2006，《生活饮用水卫生标准》（GB5749-2022），加强对消毒副产物指标的严格控制，确保饮用水供水安全。...
                </p>
              </div>
              <div className="rig">
                <Flex gap="small" vertical>
                  <Flex wrap="wrap" gap="small">
                    <Button
                      className="news_xt"
                      type="primary"
                      shape="circle"
                      icon={<ArrowRightOutlined />}
                      onClick={() => toNewDetailsId(4)}
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 我们的客户 */}
      <div
        ref={targetRef6}
        className={inView6 ? "client animationup" : "client"}
      >
        <div className="title">合作伙伴</div>
        <div className="title2">
          <span className="ico"></span>OUR CLIENTS
        </div>
        <div className="content">
          <div className="content_1 ">
            <div className="dm_big dm_big1">
              <span className="dm">沧州市孟村回族自治县自来水公司</span>
              <span className="dm">冀东水泥滦县有限责任公司</span>
              <span className="dm">唐山海港开发区供水工程管理处</span>
              <span className="dm">唐山市丰润区城乡水务集团</span>
              <span className="dm">唐山市人民医院</span>
              <span className="dm">海阳市人民医院</span>
              <span className="dm">凌源市人民医院</span>
              <span className="dm">龙井市人民医院</span>
              <span className="dm">保定市涞源县自来水公司</span>
              <span className="dm">潍坊教育学院</span>
              <span className="dm">山东朝日绿源</span>
              <span className="dm">绥中县医院</span>
              <span className="dm">河北联合大学附属医院</span>
              <span className="dm">滨州市黄河供水有限公司</span>
              <span className="dm">滕州市水利和渔业局</span>
              <span className="dm">日照市水务集团供水有限公司</span>
              <span className="dm">山东海化股份有限公司</span>
            </div>
            <div className="dm_big2 dm_big">
              <span className="dm">沧州市孟村回族自治县自来水公司</span>
              <span className="dm">冀东水泥滦县有限责任公司</span>
              <span className="dm">唐山海港开发区供水工程管理处</span>
              <span className="dm">唐山市丰润区城乡水务集团</span>
              <span className="dm">唐山市人民医院</span>
              <span className="dm">海阳市人民医院</span>
              <span className="dm">凌源市人民医院</span>
              <span className="dm">龙井市人民医院</span>
              <span className="dm">保定市涞源县自来水公司</span>
              <span className="dm">潍坊教育学院</span>
              <span className="dm">山东朝日绿源</span>
              <span className="dm">绥中县医院</span>
              <span className="dm">河北联合大学附属医院</span>
              <span className="dm">滨州市黄河供水有限公司</span>
              <span className="dm">滕州市水利和渔业局</span>
              <span className="dm">日照市水务集团供水有限公司</span>
              <span className="dm">山东海化股份有限公司</span>
            </div>
          </div>
          <div className="content_1 ">
            <div className="dm_big dm_big1">
              <span className="dm">抚顺市金汇肉联厂</span>
              <span className="dm">河北省青县自来水公司</span>
              <span className="dm">滕州市深水清河污水处理有限公司</span>
              <span className="dm">辽宁医学院附属第一医院</span>
              <span className="dm">抚顺市第二人民医院</span>
              <span className="dm">尚义县自来水公司</span>
              <span className="dm">丰南县自来水公司</span>
              <span className="dm">辽宁帝华味精食品有限公司</span>
              <span className="dm">卢龙县供水管理处</span>
              <span className="dm">锦州港股份有限公司</span>
              <span className="dm">安徽水安建设集团股份有限公司</span>
              <span className="dm">青岛西海岸公用事业集团水务有限公司</span>
              <span className="dm">青岛西海岸公用事业集团农村供水有限公司</span>
              <span className="dm">青岛暖万家市政工程有限公司</span>
              <span className="dm">青岛胶东国际机场</span>
              <span className="dm">中国东方航空股份有限公司</span>
              <span className="dm">泰安市自来水有限公司</span>
            </div>
            <div className="dm_big2 dm_big">
              <span className="dm">抚顺市金汇肉联厂</span>
              <span className="dm">河北省青县自来水公司</span>
              <span className="dm">滕州市深水清河污水处理有限公司</span>
              <span className="dm">辽宁医学院附属第一医院</span>
              <span className="dm">抚顺市第二人民医院</span>
              <span className="dm">尚义县自来水公司</span>
              <span className="dm">丰南县自来水公司</span>
              <span className="dm">辽宁帝华味精食品有限公司</span>
              <span className="dm">卢龙县供水管理处</span>
              <span className="dm">锦州港股份有限公司</span>
              <span className="dm">安徽水安建设集团股份有限公司</span>
              <span className="dm">青岛西海岸公用事业集团水务有限公司</span>
              <span className="dm">青岛西海岸公用事业集团农村供水有限公司</span>
              <span className="dm">青岛暖万家市政工程有限公司</span>
              <span className="dm">青岛胶东国际机场</span>
              <span className="dm">中国东方航空股份有限公司</span>
              <span className="dm">泰安市自来水有限公司</span>
            </div>
          </div>
          <div className="content_1 ">
            <div className="dm_big dm_big1">
              <span className="dm">唐山市第九医院</span>
              <span className="dm">甘肃定西市医疗垃圾处理站</span>
              <span className="dm">乳山市自来水有限公司</span>
              <span className="dm">凌源市钢城医院</span>
              <span className="dm">乐亭县自来水公司</span>
              <span className="dm">青岛宇通管业有限公司</span>
              <span className="dm">临沂实康水务有限公司</span>
              <span className="dm">泥布湾污水处理厂</span>
              <span className="dm">迁安市自来水公司</span>
              <span className="dm">唐山海港浩源供水有限公司</span>
              <span className="dm">枣庄市汇泉供水有限责任公司</span>
              <span className="dm">尚义县大青沟镇污水处理厂</span>
              <span className="dm">山东莘州水务集团有限公司</span>
              <span className="dm">白银有色动力有限公司</span>
              <span className="dm">费县和源水务有限公司</span>
              <span className="dm">长垣市城镇自来水有限公司</span>
              <span className="dm">莱阳市自来水公司</span>
            </div>
            <div className="dm_big2 dm_big">
              <span className="dm">唐山市第九医院</span>
              <span className="dm">甘肃定西市医疗垃圾处理站</span>
              <span className="dm">乳山市自来水有限公司</span>
              <span className="dm">凌源市钢城医院</span>
              <span className="dm">乐亭县自来水公司</span>
              <span className="dm">青岛宇通管业有限公司</span>
              <span className="dm">临沂实康水务有限公司</span>
              <span className="dm">泥布湾污水处理厂</span>
              <span className="dm">迁安市自来水公司</span>
              <span className="dm">唐山海港浩源供水有限公司</span>
              <span className="dm">枣庄市汇泉供水有限责任公司</span>
              <span className="dm">尚义县大青沟镇污水处理厂</span>
              <span className="dm">山东莘州水务集团有限公司</span>
              <span className="dm">白银有色动力有限公司</span>
              <span className="dm">费县和源水务有限公司</span>
              <span className="dm">长垣市城镇自来水有限公司</span>
              <span className="dm">莱阳市自来水公司</span>
            </div>
          </div>
          <div className="content_1 ">
            <div className="dm_big dm_big1">
              <span className="dm">青岛西海岸公用事业集团农村排水公司</span>
              <span className="dm">青岛市海润自来水集团有限公司</span>
              <span className="dm">青岛胶州自来水公司</span>
              <span className="dm">青岛灵山源泉水务有限公司</span>
              <span className="dm">上海医药集团青岛国风药业股份有限公司</span>
              <span className="dm">清控环境(北京)有限公司</span>
              <span className="dm">青岛农高孙家屯水务有限公司</span>
              <span className="dm">青岛西海岸市政工程有限公司</span>
              <span className="dm">青岛西海岸城市建设工程有限公司</span>
              <span className="dm">青岛中润城际建设工程有限公司</span>
              <span className="dm">招远市自来水公司</span>
              <span className="dm">诸城市龙泉自来水有限公司</span>
              <span className="dm">潍坊中以溴化物有限公司</span>
              <span className="dm">晖泽水务(青州)有限公司</span>
              <span className="dm">诸城市龙泉自来水有限公司</span>
              <span className="dm">滕州市中润供水有限公司</span>
              <span className="dm">茌平区融创水务有限公司</span>
              <span className="dm">禹城市润禹水务有限公司</span>
              <span className="dm">凌源供水有限公司</span>
              <span className="dm">下花园自来水公司</span>
              <span className="dm">沧州临港润捷供排水有限公司</span>
            </div>
            <div className="dm_big2 dm_big">
              <span className="dm">青岛西海岸公用事业集团农村排水公司</span>
              <span className="dm">青岛市海润自来水集团有限公司</span>
              <span className="dm">青岛胶州自来水公司</span>
              <span className="dm">青岛灵山源泉水务有限公司</span>
              <span className="dm">上海医药集团青岛国风药业股份有限公司</span>
              <span className="dm">清控环境(北京)有限公司</span>
              <span className="dm">青岛农高孙家屯水务有限公司</span>
              <span className="dm">青岛西海岸市政工程有限公司</span>
              <span className="dm">青岛西海岸城市建设工程有限公司</span>
              <span className="dm">青岛中润城际建设工程有限公司</span>
              <span className="dm">招远市自来水公司</span>
              <span className="dm">诸城市龙泉自来水有限公司</span>
              <span className="dm">潍坊中以溴化物有限公司</span>
              <span className="dm">晖泽水务(青州)有限公司</span>
              <span className="dm">诸城市龙泉自来水有限公司</span>
              <span className="dm">滕州市中润供水有限公司</span>
              <span className="dm">茌平区融创水务有限公司</span>
              <span className="dm">禹城市润禹水务有限公司</span>
              <span className="dm">凌源供水有限公司</span>
              <span className="dm">下花园自来水公司</span>
              <span className="dm">沧州临港润捷供排水有限公司</span>
            </div>
          </div>
        </div>
      </div>
      {/* 联系我们 */}
      <div
        ref={targetRef7}
        className={inView7 ? "rebation animationup" : "rebation"}
      >
        <div className="rebation_top">
          <div className="title">联系我们</div>
          <div className="title2">
            <span className="ico"></span>CONTACT US
          </div>
          <div className="content"></div>
        </div>
        <div className="rebation_bj" id="rebation_root">
          <canvas id="canvas"> canvas not support</canvas>
          <ParticleAnimation />
        </div>
        <div className="rebation_bd">
          <Formdiv />
        </div>
      </div>
    </div>
  );
}
