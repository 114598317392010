import React, { useState, useEffect } from "react";
import { Tabs, Breadcrumb} from "antd";
import { Link,useNavigate } from "react-router-dom";
import "./productsltem_tow.scss";
import useInView from '../../../../components/hook/hook'

export default function Productsltem_tow() {
  // 屏幕宽度
  const [show, setshow] = useState(window.innerWidth >= 1024)
  //监听
  const [targetRef, inView] = useInView();
  const [targetRef2, inView2] = useInView();
  const [name, setName] = useState("");
  const navigate = useNavigate()
  const tzlxwm =()=>{
    navigate('/relation')
  }
  const listData = [
    {
      text: "二氧化氯消毒系统",
      items:
        "JCT型二氧化氯发生器/JCG型二氧化氯发生器/JCF型二氧化氯发生器/复合药剂",
    },
    {
      text: "水处理加药系统",
      items: "全自动加药装置/一体化加药装置/粉体投加装置",
    },
    {
      text: "膜处理系统",
      items:
        "超滤膜自来水处理_浸没式超滤膜/超滤膜自来水处理_柱式超滤膜/MBR膜污水处理/反渗透膜处理",
    },
    {
      text: "生物除臭系统",
      items: "生物除臭系统",
    },
    {
      text: "特种离子去除系统",
      items: "特种离子去除系统",
    },
    {
      text: "自动化控制系统",
      items:
        "水厂常规自动化控制系统/地表水厂自动化控制系统/地下水厂自动化控制系统/深度处理自控系统/污水厂自动化控制系统/水厂手机APP/水质在线监测系统",
    },
    {
      text: "智慧水务平台系统",
      items:
        "智慧水务平台系统/供水管网地理信息系统（GIS）/数字化水厂/管网水质在线监测及预警系统/移动APP系统",
    },
    {
      text: "环保水处理工程",
      items: "环保水处理工程",
    },
  ];
  const listjs = [
    {
      h: "JCT型二氧化氯发生器",
      cpjs:
        "JCT型二氧化氯发生器拥有2项发明专利，专利号：1、（ZL 201310458446.4）大型高纯二氧化氯发生装置及发生方法，2、（ZL 201510613836.3）一种曝气型二氧化氯发生器，由全国化学标准物质委员会二氧化氯专业委员会监制，" +
        "是向全国杀菌灭藻工况场合、饮用水消毒企业推荐与推广的产品。技术获得中华人民共和国教育部科学进步奖一等奖，华夏建设科学技术奖二等奖。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.1/JCT型.jpg"),
      xntd:
        "反应原理%%48NaClO3+C12H22O11+24H2SO4→48ClO2+12CO2+24Na2SO4+35H2O//高纯度：二氧化氯纯度≥95%%%符合饮用水消毒要求，可放心用于饮用水消毒。//高转化率：氯酸盐转化率≥90%%%通过专利负压曝气技术实现原料的高效反应，大大减少了原料的浪费。//低成本：原料成本比普通的亚氯酸钠法制备成本低60%左右%%" +
        "原料成本低廉，设备转化率高，同时主机具有自发式反应特性，只需间隙性加热，运行成本低。//安全性高：配安全防护、故障连锁自动保护%%包括釜压、水压、液位、温度、泄露等发生异常状况时的预警与保护措施。//使用寿命长：主件耐腐蚀、性能高%%" +
        "设备的主要元器件均采用进口产品，主反应釜采用耐温、耐腐蚀、耐压的新型材料制成，运行稳定，故障率低。//自动化程度高：智能控制，自动运行%%设备所有控制信号及报警信号由PLC处理，实时反馈至人机交互界面。同时结合现场流量传感器、余二氧化氯检测仪等监控系统，可实现其投加量的复合自动控制。控制提供远端管理接口，可实现无人值守。",
    },
    {
      h: "JCG型二氧化氯发生器",
      cpjs: "JCG型二氧化氯发生器采用亚氯酸钠溶液与盐酸反应的化学工艺，即特定浓度的亚氯酸钠溶液与盐酸在负压环境下，经计量系统自动计量并输送到反应系统中，经过反应产生二氧化氯气体，稀释水经反应釜吸收系统，将反应釜内的气体溶解至稀释水中，制成一定浓度的二氧化氯消毒液，而后经由加药管路投加到待处理的水中。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.1/JCG.jpg"),
      xntd:
        "反应原理%%5NaClO2+4HCl→4ClO2+5NaCl+2H2O//高纯度：二氧化氯纯度≥98% %%符合饮用水消毒要求，可放心用于饮用水消毒。//高转化率：亚氯酸盐转化率≥90% %%无需加热，原料自发式反应。//寿命长：主件耐腐蚀、性能高%%设备的主要元器件均采用国内外优质品牌，且反应器选用进口耐腐蚀耐高压材料，保证设备正压使用的安全性。//安装方便：集成化程度高，占地小%%采用柜式安装方式，先进的工艺设计与优化的反应条件减小了装置体积，节约安装空间。" +
        "//适用性广：自发反应，全场景适用%%反应釜无需加热，只需将两种溶液投入反应器即可，同时实现二氧化氯发生器行业中正压提液技术、负压曝气技术，保证各普通场景及高背压场景的应用。//自动化程度高：智能控制，自动运行%%设备所有控制信号及报警信号由PLC处理，实时反馈至人机交互界面。同时结合现场流量传感器、余二氧化氯检测仪等监控系统，可实现其投加量的复合自动控制。控制提供远端管理接口，可实现无人值守。",
    },
    {
      h: "JCF型二氧化氯发生器",
      cpjs: "复合型二氧化氯发生器采用氯酸钠溶液与盐酸反应的化学工艺，即氯酸钠溶液与盐酸在负压环境下，经计量系统自动计量并输送到反应系统中，在60℃反应的环境中经过负压曝气反应产生二氧化氯和氯气的混合气体，动力水经过吸收系统（水射器部分），将反应釜内的气体吸出，制成一定浓度的二氧化氯与氯气的混合消毒液，而后经由加药管路投加到待处理的水中。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.1/JCF.jpg"),
      xntd:
        "反应原理%%2NaClO3+4HCl2ClO2+Cl2+2NaCl+2H2O//智能自动%%系统具备缺水、低压、缺药自动报警功能。所有的报警信号均由控制系统处理完成，可通过触摸屏人机界面了解设备的运行状态。//稳定耐用%%设备的主要元器件均采用国外名牌产品，过流管件及机体均采用高性能耐腐蚀聚氯乙烯复合材料，使设备的寿命可达8年以上。//消毒成本低%%设备采用四级反应釜，且真正实现推流式反应及负压曝气技术，高效的转化率使得原料投加量减小，运行成本低。" +
        "//安装方便%%采用柜式安装方式，先进的工艺设计与优化的反应条件减小了装置体积，节约安装空间。//安全性高%%设备具有安全防护措施和故障连锁自动保护功能，包括釜压、水压、液位、温度、泄露等发生异常状况时的预警与保护措施。//自动化程度高%%设备所有控制信号及报警信号由PLC处理，实时反馈至人机交互界面。同时结合现场流量传感器、余二氧化氯检测仪等监控系统，可实现其投加量的复合自动控制。控制提供远端管理接口，可实现无人值守。",
    },
    {
      h: "复合药剂",
      cpjs:
        "应用范围: 1. 应用于JCTF、JCGF、JCFF系列发生器的常规使用。 " +
        "2. 应用于JCT、JCG、JCF系列发生器的技改升级。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.1/复合药剂.jpg"),
      xntd: "安全高效%%复合氯酸盐及复合硫酸氢钠药剂均选用优级原料，经特殊工艺制成，使二氧化氯纯度达90%以上，消毒安全且高效。//购买简单%%不用直接购买盐酸、硫酸、氯酸钠、亚氯酸钠等药剂，减少用户购买过程中的复杂性。//使用方便%%药液由我公司统一配送，免去了原料下车、搬运、溶解、稀释、抽吸等繁琐的工作，减轻了水厂的日常管理工作量。//成本低廉%%复合药剂的原料转化率较普通盐酸氯酸钠法提升50%，不产生氯气。两种药剂的使用成本相较于次氯酸钠低20%左右，而与普通的亚氯酸钠法制备相比成本低60%左右。",
    },
    {
      h: "全自动加药装置",
      cpjs: "全自动加药装置集自动、连续、一体化的药剂溶配及投药，本装置由溶解式箱体、干粉投加系统、溶液搅拌系统、控制系统、液体投加系统构成。干粉药剂从料斗下部的螺旋推进器进入预混器与清水进行预混，被湿润的物料进入配制槽进行稀释混合，按用户要求浓度在线进行配制。本装置具有自动化程度高、性能稳定、工作可靠、菜单操作简单等特点。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.2/全自动加药1.jpg"),
      xntd: "性能特点%%装置采用三槽溢流式溶液操作系统，全自动运行，保证药液浓度恒定、溶解充分、熟化完全，适用干溶解和连续添加药剂的工艺流程。装置稳定可靠，有故障时相关设备会自动按要求停止，同时发出声光报警。 独特的药剂浸溶方式和加热系统，避免了干粉的结构和溶解不均匀。装置槽体可选多种耐腐蚀材料，独立化定制，美观耐用。控制系统自动化程度高，操作简单、方便，配件均采用进口器件，能够运行连续、平稳、安全。//用于溶解聚丙烯酰胺（PAM）、聚合氯化铝（PAC）、高锰酸钾（KMnO4）等难溶解的固态药剂。",
    },
    {
      h: "一体化加药装置",
      cpjs: "一体化加药装置采用的是机电一体化结构形式，从安装上可分为固定式和移动式(推车式)，每种形式的加药装置均配有搅拌系统、加药系统和自动控制系统。一体化加药装置是我公司专为优化水处理系统而设计的实用新型设备。该装置可实现就地控制、远程自动控制、手动和自动相互转换加药。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.2/一体化加药设备2.jpg"),
      xntd: "性能特点%%性能稳定，能耗低，结构紧凑，外形美观，占地面积小，适用范围广。全自动运行，操作方便，维修简单，加药量恒定，药剂投加量易于调节。产品结构模块化设计，一体化组合，可方便地进行功能扩展。控制系统采用优质品牌的进口器件，运行安全稳定。可根据不同的水质和用户的不同要求，灵活配置。国内外多种品牌、不同材质、各种档次的设备及部件，用户可任意选配。//用于聚丙烯酰胺（PAM）、聚合氯化铝（PAC）、高锰酸钾（KMnO4）、乙酸钠、三氯化铁等固/液态药剂的溶解与投加。",
    },
    {
      h: "粉体投加装置",
      cpjs:
        "粉体投加装置是涵盖粉料储存、粉料投加、溶解、计量投加等过程的一套独立的、完整的投加装置，该装置克服了以往人工投加精度不够、粉末飘散、污染环境等各种弊病，装置性能稳定、投加精度高，操作可完全自动化，工作环境清洁，同时节省了大量的人力物力成本。" +
        "粉体投加装置主要组成部分：上料储存系统、粉料定量投加系统、自动浆液制备系统、浆液定量泵送系统、电气控制系统。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.2/活性炭投加装置2.jpg"),
      xntd: "性能特点%%自动化程度高，系统采用PLC控制。在触摸屏上操作及显示设备运行状态，具有直观的人机界面，操作简单、方便。控制系统全自动投加溶液，活性炭投加量自动跟随水流量的变化，保证所配置溶液的比例恒定，具有全自动、手动控制两种方式。系统稳定可靠，在投加比例设定后，根据运行条件配置，过程完全自动运行，有故障时相关设备会自动按要求停止，工作状态及故障信息可同时在触摸屏及中控室显示。采用螺旋给料器投加粉末，保证投料均匀、分散。设备整体密封性高，密闭性能提高粉末卸货效率、提高投加计量水准，同时达到防潮效果。//用于粉末活性炭、石灰粉末、碳酸钠粉末等粉态药剂的溶解与投加。",
    },
    {
      h: "超滤膜自来水处理",
      cpjs: "超滤（简称UF）是利用纳米级的物理孔径在一定的压力作用下，对料液中的物质进行分离、净化、纯化和浓缩的纯物理过程，它不引起分离物质化学性质的变化，是一种安全和可靠的过滤和浓缩方法，可有效去除水中的悬浮物、胶体、色度、浊度、有机物、细菌和大肠杆菌等杂质；PVDF超滤膜组件根据膜丝的安装形式可分为浸没式超滤膜组件和柱式超滤膜组件。",
      imgURL: require("../../../../assets/imgs/product/超滤膜自来水处理.jpg"),
      xntd: "",
    },
    {
      h: "超滤膜自来水处理_浸没式超滤膜",
      cpjs: "浸没式超滤是一种应用于市政污水、工业废水、自来水和纳滤%%反渗透预处理等多领域的超微滤膜产品，与传统的超滤膜全封闭式的反应空间不同，浸没式超滤组件采用半封闭开放式的结构，反应空间更大，水质适应性更强，结合配套的自清洗单元、加药单元和自控单元等，形成闭路连续的过滤系统。浸没式超滤工艺以浸没式超滤膜为核心，具有产水水质好、耐冲击性更强、运行能耗更低、运行成本低、占地面积小、施工周期短、高耐污染等特点。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.3/浸没式超滤膜  (1).jpg"),
      xntd:
        "应用范围%%饮用水处理：自来水厂的提标改造。" +
        "%%地表水处理：对地表水进行净化处理，提升地表水水质。" +
        "%%污水深度处理：对二级处理出水进行进一步的深度处理。",
    },
    {
      h: "超滤膜自来水处理_柱式超滤膜",
      cpjs: "柱式超滤是一种液体切向流动和压力驱动的过滤过程，并按分子量大小来分离颗粒，几乎可截留所有的大分子物质和杂质。超滤装置一般采用外压式中空纤维超滤膜，允许小分子物质和溶解性固体(无机盐)等通过，同时可去除所有颗粒、悬浮物、细菌、病菌和原生物、胶体(非活性炭、铁、铝等)、蛋白质、微生物和大分子有机物。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.3/柱式超滤膜.jpg"),
      xntd:
        "材质:%%超滤膜材质采用PVDF，该种材质具有强度高、亲水性好、耐有机污染、耐酸碱等特点，特别适用于中水回用、地表水等高污染水源，出水SDI＜2（90%时间），SDI≤3（10%时间），满足反渗透进水要求。超滤装置按照死端过滤运行方式设计，保留错流接口方式。每套超滤装置反洗浓水排水须装流量控制阀，以控制水的回收率。%%超滤系统一般包括超滤反洗单元、在线化学清洗单元（CEB）、控制单元和加药单元四部分。" +
        "%%超滤膜出水能达到：" +
        "%%出水SDI值：5年内：SDI≤3。" +
        "%%胶体硅去除率：≥99%（15℃）" +
        "%%孔径分布均匀，范围：0.01～0.1μm。" +
        "%%跨膜压差：三年内：＜1kg/cm2，五年内：＜2kg/cm2" +
        "%%设计平均通量：按设计导则的低限选取。推荐膜设计通量：＜60L/m2/h。" +
        "%%断丝率要求：5年内≤6‰。" +
        "//应用范围%%1、饮用水净化：饮用水中细菌、悬浮物、真菌、微生物以及病毒进行处理和净化，以实现对其含量的有效控制。相比于其他传统的饮用水净化方法，超滤膜不仅能够有效去除水中微米级颗粒，还能有效去除传统水处理技术无法处理的纳米级颗粒，以及能够有效去除水中的细菌、悬浮物等大量有害物质，提升水质。" +
        "%%2、不同相分物质的提纯浓缩、分离。" +
        "%%3、工业高纯水工程中进反渗透膜之前水质预处理。" +
        "%%4、中水回用、地表水深度处理等。",
    },
    {
      h: "MBR膜污水处理",
      cpjs:
        "MBR一体化污水处理设备的核心部件是膜生物反应器（MBR），它是膜分离技术与生物技术有机结合的新型废水处理技术。" +
        "污水进入MBR系统。A区为兼氧区，放置填料，并与活性污泥进行充分接触。O区为好氧区，放置膜组器，使用PVDF膜将活性污泥和大分子有机物质截留住，省掉二沉池。活性污泥浓度因此大大提高，水力停留时间（HRT）和污泥停留时间（SRT）可以分别控制，而难降解的物质在反应器中不断反应、降解。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.3/一体化污水处理设备 MBR膜.jpg"),
      xntd:
        "优势特点%%采用成熟的A/O生化处理+MBR膜工艺路线，具有较好的耐冲击负荷能力，以适应水质、水量变化的特点。 " +
        "%%采用污泥前置回流硝解工艺，大大降低污泥的生成量。" +
        "%%采用新型填料，挂膜快，寿命长，处理见效快，其中有机污染物去除率高，出水水质稳定。" +
        "%%充分考虑二次污染产生的可能性，降低其影响程度。" +
        "%%采用集中控制、自动化运行，易于管理维修，提高系统可靠性、稳定性。" +
        "%%紧凑，体积小，占地面积小，系统处理设施可全部设置在地表以下，不占地表面积，可作绿化，又利于防冻（风机控制室除外）。" +
        "//应用范围%%MBR一体化污水处理设备适用于住宅小区、村庄、村镇、办公楼、商场、宾馆、饭店、疗养院、机关、学校、部队、医院、高速公路、铁路、工厂、矿山、旅游景区等产生的生活污水和与之类似的屠宰、水产品加工、食品等中小型规模工业产生的有机废水的处理和回用。",
    },
    {
      h: "反渗透膜处理",
      cpjs: "反渗透膜是一种模拟生物半透膜制成的具有一定特性的人工半透膜，是反渗透技术的核心构件。反渗透技术原理是在高于溶液渗透压的作用下，其他物质不能透过半透膜而将这些物质和水分离开来。反渗透膜的膜孔径非常小，因此能够有效地去除水中的溶解盐类、胶体、微生物、有机物等。系统具有水质好、耗能低、工艺简单、操作简便等优点。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.3/反渗透膜（1）.jpg"),
      xntd:
        "基本组成%%反渗透系统装置基本组成：包括保安过滤器、反渗透高压泵、反渗透组件、配套仪表、阀门、管系及本体组架以及加药、清洗设备等。" +
        "//反渗透装置主要性能要求：%%RO装置产水量：根据设备具体要求;RO装置脱盐率：≥98% ;RO装置水回收率: 75%（运行三年内）" +
        "//应用范围%%反渗透设备适用于食品、饮料、化工、医院、电子等行业的水处理设备，也可作为社区、工业区、油田的净水屋的核心设备。",
    },
    {
      h: "生物除臭系统",
      cpjs:
        "生物除臭是采用生物法，通过专门培养在生物滤池内生物填料上的微生物膜对废臭气分子进行除臭的生物废气处理技术。当含有气、液、固三项混合的有毒、有害、有恶臭的废气经收集管道导入本系统后通过培养生长在生物填料上的微生物菌株形成的生物膜来净化和降解废气中的污染物。" +
        "生物除臭设备抗冲击能力强，对负荷的变化有较好的适应能力，生物填料除臭效率高，湿度保持性好，具有吸附污染物和微生物生长的适宜环境。生物滤池中选用菌种受温度影响小，除臭效率高，可以不间断工作。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.4/生物除臭2.jpg"),
      xntd:
        "可避免或减少二次污染%%生物处理恶臭气体一般将硫系、碳系、氮系等各种恶臭成分，以及苯酚、氰等有机的有毒成分通过微生物的作用，氧化和分解成CO2、H2O、H2SO4等物质。" +
        "//运行成本低%%生物处理VOC是以VOC成分作为生物体内的能源，只要使微生物与VOC成分相接触，就可以完成氧化和分解过程。与物化处理VOC法相比，微生物生长适宜的温度一般为20-30 ℃，接近常温，因此生物处理VOC过程一般不需加热，不仅可节省能源和资源，而且处理成本也比较低廉。" +
        "//处理效率高%%通过控制适当的负荷条件与气液接触条件，达到良好的处理效率。" +
        "//设备运行采用全自动控制%%运行稳定，无需人工操作。易损部件少，维护管理简单，基本可以实现无人管理，工人只需巡视是否有机器发生故障。" +
        "//适用寿命长%%填料具有良好的结构稳定性和透气性能，因此生物滤池压力损失低。使用寿命长，一般可以达到10 年以上。",
    },
    {
      h: "特种离子去除系统",
      cpjs:
        "特种离子去除是指将矿井水、自然水体、市政供水及其他相应水相体系中的氯离子、硝酸根离子、氟离子、砷离子、汞离子、三价铬离子、硬度等污染物指标离子通过选择性吸附、交换、氧化、还原等方法进行深度去除，从而保证水相体系正常使用功能。" +
        "特种工艺有：离子交换吸附、氧化还原、活性炭吸附、催化反应等，主要是根据需要去除离子的物理化学性质、水环境、去除指标物浓度等因素确定，其中离子交换吸附工艺应用最为常见。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.5/特种离子去除1.jpg"),
      xntd:
        "高效:%%设备整体设计配套合理，使树脂的有效工作交换容量得以充分发挥。" +
        "//省工%%自动化程度高，无需设专人值守。" +
        "//省水：%%制水率达99%。" +
        "//省电：%%采用虹吸再生原理，无需盐泵，耗电量仅相当于手动软水设备的1%。" +
        "//占地空间小：%%只需提供树脂罐和盐罐的占地空间，节省管路、盐泵所占空间。" +
        "//调整方便：%%用户可根据实际需要，自行调整再生周期和再生时间。" +
        "//运行费用低：%%自动化控制，设备自适应水量变化，有效计量产水量、计量再生剂的用量，避免了再生时再生剂的浪费，同时可节省大量的人工费。",
    },
    {
      h: "水厂常规自动化控制系统",
      cpjs: "随着经济的发展和社会进步，城市给水行业遇到了严重的挑战。对水处理厂而言，符合标准的原水日益缺乏，而人们对水质的要求不断提高。这就导致水厂的处理工艺不断改进，并对水厂自动控制系统提出更高的要求。为满足水处理工艺优化控制的需要，水厂自动化控制系统的功能已从当初简单的数据监测发展到今天的先进控制。当今，一个现代化水厂自动化控制系统的组成结构、功能和性能要求、各种控制策略及其发展方向等方面的问题，越来越受到同行们的关注。水厂实现自动化的根本目的是提高生产的可靠性和安全性，实现优质、低耗和高效供水，获得良好的经济效益和社会效益。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/水厂常规.jpg"),
      xntd:
        "主要功能%%实现投药、投氯系统的自动化控制；" +
        "%%实现滤池反冲洗的全自动控制，并且动态在线显示每个滤池的工作情况；" +
        "%%对入厂水电动阀门进行控制，实现全自动系统控制和远程控制其开停及任意开度；" +
        "%%各种水质数据（包括源水浊度、PH值；出厂水浊度、PH值、余氯、出厂水流量、管网测压点压力、滤池每格的反冲洗情况和清水池液位）全部实现在线显示和自动化监控；" +
        "%%历史数据收集、报表打印（包括日报、月报、年报），便于档案收集、管理；" +
        "%%数据通信功能；" +
        "%%设置中央LED拼接屏，直观反映全厂的生产情况；" +
        "%%安全登录和密码保护功能；" +
        "%%人机界面功能；" +
        "%%操作控制功能。",
    },
    {
      h: "地表水厂自动化控制系统",
      cpjs:
        "一个地表水厂自动化监控系统通常分为设备层、控制层及管理层三层结构。控制层完成具体的取水、混凝、沉淀、过滤、送水等环节的测控；管理层主要负责状态监控、数据采集、故障报警处理等工作。控制层的各工艺环节位置分散，位于中控室的控制中心将进行统一监控，因此自来水厂具有集中监控、分散控制的特点。" +
        "通过建设高稳定性和可靠性的自来水厂综合自动化系统，实现对自来水厂水处理过程的自动化管理与控制，节省现场操作所耗费的人力物力，方便生产管理、提高设备的利用率，保证自来水厂长期安全无故障运行。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/地表水厂.jpg"),
      xntd:
        "主要功能%%实现加药、加氯系统的自动化控制，进行精准加药；" +
        "%%实现滤池反冲洗的全自动控制，动态在线显示每个滤池的工作情况；" +
        "%%实现二泵房恒压供水，保证送水安全；" +
        "%%设置中央液晶拼接屏，直观反映全厂的生产情况；" +
        "%%历史数据收集、报表打印，便于档案收集、管理。",
    },
    {
      h: "地下水厂自动化控制系统",
      cpjs:
        "地下水厂水源一般取自地深水井，其自动化控制系统以统一调度、集中监控的方式，主要实现一下功能：" +
        "对水源井内水位、压力、流量的采集及深水泵的远程控制；" +
        "监测水厂的进出水流量、水池水位、出厂压力、水质参数；" +
        "加氯设备的自动监控；" +
        "送水泵组设备可实现机旁控制、手动动控制、自动控制三级控制。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/地下水厂.jpg"),
      xntd: "",
    },
    {
      h: "深度处理自控系统",
      cpjs:
        "深度处理的自动控制系统是由运行控制室上位机总站，反冲泵房PLC主站，炭池工艺处理PLC分站及主要工艺设备和在线仪表组成。上位机用组态界面软件，根据监控要求开发应用程序，实时采集、监控和处理深度处理运行信息；运行人员在控制室通过操作上位机即可实现对工艺设备进行控制、正常运行监视和调整、以及异常与事故工况的处理。如进水泵流量控制，送水泵的流量或压力控制，滤池反冲洗的自动运行等。" +
        "上位机与可编程控制器通过通讯网络（工业以太网）交换信息。工艺要求的控制流程和控制逻辑，由系统集成人员编制好程序存储在PLC存储器里，PLC按照自控程序自动控制主要工艺设备运行，PLC是控制系统的枢纽，承上启下使自动控制得以实现。 " +
        "控制系统集成实现的关键在于不仅满足监控需求，更要注重信息统计处理功能的开发，对采集信息的统计处理最能体现系统先进程度，对运行要求及对工艺的符合程度是衡量控制系统科学合理的唯一标准。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/深度处理.jpg"),
      xntd: "",
    },
    {
      h: "污水厂自动化控制系统",
      cpjs: "污水处理厂自控系统是整个污水处理工程的重要组成部分，其设计好坏与控制设备选择是否适当，不仅关系着自控系统的性价比的高低而且对以后整个污水处理厂运行维护的难易有着重要影响。污水处理厂的自控系统一般由现场仪表和执行机构、信号采集控制器（PLC）和人机界面（组态软件）三部分组成。自控系统的构建主要是指三部分系统形式和设备的选择。执行机构主要是根据工艺的要求由工艺专业确定，预留自控系统的接口，信号采集控制部分主要包括基本控制系统的选择以及系统确定后控制设备和通讯网络的选择。人机界面主要是指中控室和现场控制设备的选择。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/污水厂.jpg"),
      xntd:
        "主要功能%%整个自动化控制系统：包含现场水质在线监测系统（现场仪表）、PLC控制系统、数据传输通信系统、中央控制室监测系统等。通过运用自动化控制系统可以取得较好的经济效益：" +
        "%%减轻生产运营工人的劳动强度；" +
        "%%提高水处理生产的可靠性和稳定性；" +
        "%%降低生产成本，提高生产管理水平；" +
        "%%减少质量事故。 ",
    },
    {
      h: "水厂手机APP",
      cpjs:
        "将水厂自动化系统的数据借助智能手机APP进行查询。可以让生产管理者和操作者及时了解、掌控水厂生产过程和设备运行的情况。" +
        "利用手机APP可实现在移动终端上实时查看生产过程的即时数据，可快速生成报表、趋势图表等，方便的实现移动办公。使得生产过程对管理层更加透明，为提高生产管理效率提供帮助。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/手机APP.jpg"),
      xntd: "",
    },
    {
      h: "水质在线监测系统",
      cpjs: "水质在线自动监测系统（On-line Water Quality Monitoring System）是一个以在线分析仪表和实验室研究需求为服务目标，以提供具有代表性、及时性和可靠性的样品信息为核心任务，运用自动控制技术、计算机技术并配以专业软件，组成一个从取样、预处理、分析到数据处理及存贮的完整系统，从而实现对样品的在线自动监测。自动监测系统一般包括取样系统、预处理系统、数据采集与控制系统、在线监测分析仪表、数据处理与传输系统及远程数据管理中心，这些分系统既各成体系，又相互协作，以完成整个在线自动监测系统的连续可靠地运行。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.6/水质在线监测.JPG.jpg"),
      xntd: "",
    },
    {
      h: "智慧水务平台系统",
      cpjs: "智慧水务是利用物联网、大数据、云计算、移动互联网等技术，远程在线实时获得整个水务系统（取水、制水、供水、排水）的运行状态信息，将生产SCADA、GIS、水质预警监测、二供系统、安防门禁、工单系统、水文气象信息、手机APP等系统应用集成在一起，实现“跨专业、跨系统”的数据共享、融合和分析，打破传统的单个系统的应用模式，消除信息孤岛，实现“信息全面感知、数据综合分析、高度智慧决策”的应用模式。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.7/智慧水务.jpg"),
      xntd:
        "平台特点%%一个平台、整合关键、掌控全局" +
        "%%与GIS结合，数据与位置融合，直观地了解状况" +
        "%%以数据为中心，进行数据挖掘和融合，实现智慧生产、智慧管网、智慧服务、决策指挥" +
        "%%通过大屏进行数据的综合展示" +
        "%%系统整合时，原有的各个子系统相对独立，无需做大的改动" +
        "//智慧生产%%是对水厂日常运维的各个环节进行监管，可降低供制水能耗及成本，提升运维效率和管控水平，减少和控制人员成本。" +
        "%%智能加药：实现取水制水全过程自动化控制，水质达标节能降耗。" +
        "%%水厂监控总图：自动化控制与厂区布局紧密结合，生产状况一目了然。" +
        "%%整合安防门禁系统：实现与厂内安防门禁系统智能融合，事故问题快速定位、高效处理。" +
        "%%整合视频系统：通过视频监控、泄漏检测装置、吸收处理装置等智能联动，安全生产高枕无忧。" +
        "//智慧管网%%是对供水企业的核心资产“管网”，进行智慧化运维管理和科学化调度，从而达到减少爆管、降低漏失率，保证安全供水，提升供水效益。" +
        "%%管网监测：实现管网压力和管网水质在线监测。" +
        "%%数据统计：压力监测数据统计、压力异常统计、设备故障统计、报警统计。" +
        "%%科学调度：依据实时在线管网模型，生成实时调度方案；依据离线分析管网模型，生成离线规划方案；依据管网漏损位置，部署调度解决方案；面向调度一线人员，提供供水调度科学依据。" +
        "%%报警预警系统：具备微信报警、短信报警等，逢变即报。在线分析爆管、压力流量预警等事故，依据分析结果进行响应决策。速度快，用户体验好。" +
        "//智慧服务%%是将供水客户的所有业务数据有机的进行关联、分析和展示，为客户提供更加便捷的服务，让供水客户感受到智慧水务带给他们的便捷和更加高效的服务。" +
        '%%通过统一的对外服务电话、微信、网站，多方式为客户提供咨询、报修、投诉、查询等多方面的服务，打造"一站式服务"管理模式。系统不仅具备卓越的话务功能，而且具备完善的业务处理功能，实现了与水司各种业务系统的对接，显著提高了为供水客户服务的效率。' +
        "//决策指挥%%为水务管理者把握全局、运筹帷幄提供决策依据。直观的界面，展现企业各环节的经营数据，洞察企业的运营状况，帮助水务企业将运营的海量数据转化成高价值的可获取信息。" +
        "%%平台以“数据+地图+业务”为理念，以KPI中构建的数据为基础，从空间维度、业务视角展示水务各系统的数据，并在此基础上进行综合展示、分析和报表。用户根据需求进行个性化配置，满足日益变化的业务需求。管控决策分析KPI实现了统一平台化管理，使业务操作更加方便快捷，交互性更加突出。" +
        "%%可以从不同维度、不同空间对生产运维、管网管理、客户营销、计量管理、工程管理、综合管理等进行综合管控决策分析展现。",
    },
    {
      h: "供水管网地理信息系统（GIS）",
      cpjs: "采用GIS方式实现对供水管网系统中所有管线、设备、构筑物（水池、水塔等）空间位置信息、属性信息（如埋深、材质、年代、口径、连接、用途等）进行统一的数据管理。系统提供完整的数据管理功能，主要包括管网数据查询、信息统计、数据监理查错、数据更新入库、以及数据输出等功能，以及包括管网设备的信息管理、管网设备日常维护管理等功能。为城市的供水地下管网数据及相关设备信息的管理、更新和维护工作，提供全面可靠的系统功能。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.7/GIS.jpg"),
      xntd: "",
    },
    {
      h: "数字化水厂",
      cpjs: "利用计算机图形技术，采用全三维3D实景仿真技术建立数字孪生水厂，对水厂厂区内道路、建筑、房间、设备、管线、主要工艺做精细化建模，整合场内各类硬件，还原虚拟现场，完成数字建筑的虚拟化；同时，将水厂SCADA、设备资产管理、安防等数据融为一体，实现水厂全数字化在线运营。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.7/数字化水厂.jpg"),
      xntd: "",
    },
    {
      h: "管网水质在线监测及预警系统",
      cpjs: "对水源地，水厂，二供水池，直至用户水龙头及污水、排水管网等各个环节的水质参数（COD、氨氮、浊度、色度、电导率、pH值、余氯等）进行实时监测；实现了三级预警的标准化管理，基于国家标准，结合供水标准与本地水源的实际情况，在该系统设置标准线和内控线，建立所有水厂水质的在线监测预警，一旦水质出现问题，自动触发三级预警机制，实时发送报警信息。是提高工艺运行管理水平，应对应急突发事故，节能降耗，最终为饮用水水质安全提供保障的有效手段。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.7/管网水质在线监测及预警.jpg"),
      xntd: "",
    },
    {
      h: "移动APP系统",
      cpjs:
        "APP应用统一门户，一键登录，使用户可以有效地整合内部各种应用资源，使用单一的入口访问多种类型信息、多个应用系统。为工作人员提供各自权限内的工作界面，通过该统一界面可以对后端各业务系统和其他应用系统进行访问。" +
        "面向不同岗位水务工作人员，满足水司日常运营管理以及生产、管网、巡检维护等业务领域的移动化办公需求，提供运行实时监测、运营管理、巡检维护、信息查询、流程审批、业务处理、消息推送等功能，做到灵活高效管理、快速反应与决策，有效提高办公效率。",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.7/移动APP系统.jpg"),
      xntd: "",
    },
    {
      h: "环保水处理工程",
      cpjs: "净污水厂自控工程及工艺设备总包等",
      imgURL: require("../../../../assets/imgs/product/产品中心/2.8/环保工程.jpg"),
      xntd: "",
    },
  ];
  const items = [];
  const breadcrumb = [
    { title: <Link to="/">首页</Link> },
    { title: <Link to="/products">产品中心</Link> },
    { title: <Link to="/products/products_tow">{name}</Link> },
  ];
  const operations=<div ref={targetRef} className={inView ? "mbx animationright":"mbx"}>
  <Breadcrumb separator=">">
    <img
      src={require("../../../../assets/imgs/client/pos_ico.png")}
      alt=""
    />
    <span>您当前位置： </span>
    <Breadcrumb.Item>
      <Link to="/">首页</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to="/products">产品中心</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to="/products/products_tow">{name}</Link>
    </Breadcrumb.Item>
  </Breadcrumb>
  {/* 124253656879011234576890 */}
</div>
  const tit = sessionStorage.getItem("products_tow")

  // 查找匹配的项
const matchedItem = listData.find(item => item.text === tit);

if (matchedItem) {
  // 对每个子项进行处理
  matchedItem.items.split("/").forEach((itemName, index) => {
    const matchedJs = listjs.find(jsItem => jsItem.h === itemName);

    if (matchedJs) {
      const { cpjs, imgURL, xntd } = matchedJs;
      const xntdItems = [];

      // 分割详细资料
      if (xntd) {
        xntd.split("//").forEach(detail => {
          const detailParts = detail.split("%%");
          xntdItems.push(detailParts);
        });
      }

      items.push({
        key: index + 1,
        label: itemName,
        children: (
          <div className="mian">
            <div className="img_box">
              <img className="img" src={imgURL} alt="" />
              <div className="title_ms">
                <h2 className="title">{itemName}</h2>
                <div className="bt">产品简介: </div>
                <div className="span">{cpjs}</div>
                <div className="title_bott">
                  <button onClick={tzlxwm}>我要询价</button>
                  <span className="lx">
                    <span>客服热线:</span>
                    <span className="ys">0532-80982181</span>
                  </span>
                </div>
              </div>
            </div>
            {xntdItems.length > 0 ? (
              <div className="content_tow">
                <h2>
                  <span className="ico"></span>详细资料
                </h2>
              </div>
            ) : null}
            {xntdItems.map((detail, detailIndex) => (
              <div key={detailIndex} className="content_tow">
                <div className="bt">{detail[0]}</div>
                {detail.map((part, partIndex) => (
                  <div key={partIndex} className="span">
                    {part}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ),
      });
    }
  });
}
 console.log('列表',items)
  useEffect(() => {
    setName(tit);
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setshow(false);
      } else {
        setshow(true);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // 空数组作为第二个参数，表示只在组件加载时执行
  return (
    <div className="products_tow">
      <div ref={targetRef2} className={inView2 ? "content animationup":"content"}>
        {
          show?<><Tabs tabBarExtraContent={operations} items={items} /></>:<div><div ref={targetRef} className={inView ? "mbx animationright":"mbx"}>
          <img src={require("../../../../assets/imgs/client/pos_ico.png")} alt="" />
          <span>您当前位置： </span>
          <Breadcrumb items={breadcrumb} />
        </div><Tabs defaultActiveKey="1"  items={items}  /></div>
        }

      </div>
    </div>
  );
}
