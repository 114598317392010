import { useEffect, useState, useRef } from 'react';

const useInView = (
    options = {
        root: null,
        rootMargin: '0px 0px',
        threshold: 0.1,
    },
    // triggerOnce = true, // 是否只触发一次
) => {
    const [inView, setInView] = useState(false);
    const targetRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                    
                        // 触发一次后结束监听
                        // observer.unobserve(entry.target);
                        observerRef.current.unobserve(entry.target);
                    
                }
            });
        }, options);

        if (targetRef.current) {
            // 开始监听
            // observer.observe(targetRef.current);
            observerRef.current.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                // 组件卸载时结束监听
                // observer.unobserve(targetRef.current);
                observerRef.current.unobserve(targetRef.current);
            }
        };
    }, [options]);

    return [targetRef, inView];
};

export default useInView;
