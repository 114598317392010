import React, { useRef, useEffect, useState } from "react";
import {  Link } from 'react-router-dom';
import { ArrowRightOutlined } from "@ant-design/icons";
import "./SOLUTION.scss";
const SOLUTION = () => {
  //选项卡
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // 观察
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  //   进入详情页
  const lookDetails = (tab) => {};
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // entry.isIntersecting 为 true 时表示元素已经进入视口
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // 停止观察
        }
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);
  return (
    <div className="figure">
      <div>
        <div className="tab-content">
          {activeTab === "tab1" && (
            <div>
              <div className="text text1">
                <h1>智慧供水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">智慧厂网</div>
                <div className="content">
                  面向水务企业调度管理部门，以“监控一体化、数据集中化、管控平台化、支撑智慧化”为建设思路，打造供水“智慧中枢”，助力城市供水安全。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab2" && (
            <div>
              <div className="text text2">
                <h1>智慧供水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">水务企业管理</div>
                <div className="content">
                  为水务企业提供办公管理、工程管理、资产管理、指标分析、移动办公管理、数据安全管理、智慧决策等一系列高效协同的管理手段与工具，赋能水务企业经营管理，提升企业经营服务水平。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab3" && (
            <div>
              <div className="text text3">
                <h1>智慧供水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">二次供水</div>
                <div className="content">
                  二次供水关乎人民群众饮水安全与正常稳定生活，二供泵房往往建设标准不一、环境复杂、管理难度高，本方案可为水务企业提供二次供水的接收、管理、决策、降耗、运维等支撑，保障最后一公里用水安全。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab4" && (
            <div>
              <div className="text text4">
                <h1>智慧供水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">净水厂自动化控制</div>
                <div className="content">
                  系统具备数据采集、比较、学习、优化功能，每一次生产运行的环境条件参数与产生的结果都有完整明确的对应结果。集合中控室控制中心、水源井测控系统、消毒系统、水质在线监测系统、二次变频恒压供水系统于一体，保障水厂供水能力。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab5" && (
            <div>
              <div className="text text5">
                <h1>智慧供水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">智能远传抄表</div>
                <div className="content">
                  适用于远程监测工厂、酒店、学校、医院等大用户的用水量，各区域的用水总量进行自动化抄表。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="tab-buttons_t">
          <div ref={targetRef} className={isVisible?"tab-buttons animationIn":"tab-buttons"}>
            <div
              onClick={() => handleTabClick("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab1"
                      ? require("../assets/imgs/first_imgs/解决方案/智慧厂网2.png")
                      : require("../assets/imgs/first_imgs/解决方案/智慧厂网.png")
                  }
                  alt=""
                />
                <div>厂网调度</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab2")}
              className={activeTab === "tab2" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab2"
                      ? require("../assets/imgs/first_imgs/解决方案/水务企业2.png")
                      : require("../assets/imgs/first_imgs/解决方案/水务企业.png")
                  }
                  alt=""
                />
                <div>企业管理</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab3")}
              className={activeTab === "tab3" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab3"
                      ? require("../assets/imgs/first_imgs/解决方案/二次供水2.png")
                      : require("../assets/imgs/first_imgs/解决方案/二次供水.png")
                  }
                  alt=""
                />
                <div>二次供水</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab4")}
              className={activeTab === "tab4" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab4"
                      ? require("../assets/imgs/first_imgs/解决方案/净水自控2.png")
                      : require("../assets/imgs/first_imgs/解决方案/净水自控.png")
                  }
                  alt=""
                />
                <div>水厂自控</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab5")}
              className={activeTab === "tab5" ? "active" : ""}
            >
              <div className="tab-buttons_tab ">
                <img
                  src={
                    activeTab === "tab5"
                      ? require("../assets/imgs/first_imgs/解决方案/远程抄表2.png")
                      : require("../assets/imgs/first_imgs/解决方案/远程抄表.png")
                  }
                  alt=""
                />
                <div>远程抄表</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SOLUTION;
