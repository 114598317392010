import React from "react";
import "./NewsDetails1.scss";
import useInView from '../../../components/hook/hook'
const NewsDetails = () => {
      //监听
      const [targetRef, inView] = useInView();
      const [targetRef1, inView1] = useInView();
      const [targetRef2, inView2] = useInView();
  return (
    <div className="newsdetails1_main">
      <div className="newsdetail_box">
        {/* 头部 */}
        <div ref={targetRef} className={inView ? "news1_top animationup":"news1_top"}>
          <h1>
            青岛西海岸新区：深入开展农村供水水质提升行动，农村供水安全有保障！
          </h1>

          <p>
            随着我国农村经济的不断发展，农村饮用水安全问题逐渐引起人们的广泛关注，从“喝上水”到“喝好水”，已成为人们的殷切期望。青岛作为北方资源性缺水城市，连续多年将农村饮水安全列入市办实事，推动实施多项农村供水提标升级项目，青岛供水安全保障能力有了巨大提升。
          </p>
          <p>
            西海岸新区作为青岛发展龙头，计划用三年时间，深入开展农村供水水质提升专项行动，全面提升农村供水水质保障水平。青岛西海岸公用事业集团积极推进农村饮水设施改造提升工程，对大场、海青、大村、琅琊、张家楼等10个镇街22座农村规模化水厂及供水管网进行改造提升，保障青岛西海岸农村供水水质安全；建成农村规模化供水集中监控平台和“智慧水务”中心调度平台，通过数据传输、视频等在大场水厂实现对其他乡镇规模化供水的自动化远程操作，实现从原水到净水、从生产到输送全过程数据采集、可视化展示和异常预警，快速发现和解决农村供水中出现的问题，保障农村供水量质无忧。
          </p>
          <p>
            以大场水厂为例，水厂采用“网格絮凝池—平流沉淀池—虹吸膜池—清水池—供水泵房—管网”的净水工艺，设计供水能力2万吨/日，供水服务面积117平方公里，主要为周边87个行政村及镇驻地企业供水，供水服务人口5.35万人。2021年，大场水厂荣获中国水利部“2021年度农村供水规范化水厂”荣誉称号。
          </p>

          <img
            loading="lazy"
            className="middleImg"
            src={require("../../../assets/imgs/news/农饮/大场水厂.png")}
            alt=""
          />
        </div>
        {/* 中间 */}
        <div className="news1_middle">
          <div ref={targetRef1} className={inView1 ? "t1 animationleft":"t1"}>
            <h2>一、水厂设备改造</h2>
            <b>1.二氧化氯发生器</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农饮/二氧化氯发生器.png")}
              alt=""
            />
            <p>
              《生活饮用水卫生标准》GB5749-2022对于规模化供水提出明确要求。二氧化氯作为第四代新型消毒剂，具有高效、安全、广谱、作用剂量低、反应快、效果好和持续时间长，且无残留无“三致”物质，对人体无刺激等优点，是一种真正绿色环保的新型消毒产品。
            </p>
            <p>
              针对农村供水规模小、水量少的特点，包括大场水厂在内的22座农村规模化水厂，均采用二氧化氯发生器（青岛巨川）。该设备工艺结构先进，集成化程度高，占地面积小，节约安装空间。使用安全、方便，运行成本低，设备寿命可长达8年以上。
            </p>
            <p>
              发生器采用复合氯酸盐溶液与复合硫酸氢钠溶液反应的化学工艺，制得二氧化氯纯度高达95%，消除了盐酸等药剂在运输、储存、使用过程中的安全隐患，水厂不用直接购买盐酸、硫酸、氯酸钠、亚氯酸钠等药剂，减少购买过程中的复杂性。同时药剂由设备厂家统一配送，免去了原料下车、搬运、稀释、抽吸等繁琐的工作，减轻了水厂安全监管的难度和日常管理工作量，降低管理难度。
            </p>
            <b>2.PAC投加设备</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农饮/PAC投加设备.png")}
              alt=""
            />
            <p>
              PAC投加设备器件均选用国内外先进品牌，运行稳定，使用寿命长。加药过程由PLC控制，实现全自动运行，控制系统可根据接收的进水流量、进水在线浊度值信号，动态地即时调节PAC的投加量，实现更好的絮凝沉淀效果。
            </p>
          </div>
          <div ref={targetRef2} className={inView2 ? "t2 animationright":"t2"}>
            <h2>二、“智慧水务”平台</h2>
            <p>
              在大场水厂，智慧水务中心调度平台24小时在岗，守护青岛西海岸农村水厂正常运行。由青岛巨川环保科技有限公司建设，利用物联网、大数据、云计算、移动互联网等技术，将生产SCADA、GIS、水质预警监测、安防系统、工单系统、手机APP等系统应用集成在一起，实现“信息全面感知、数据综合分析、高度智慧决策”的应用模式。覆盖西海岸新区10个镇街的22座农村规模化水厂，服务人口约25万。2022年，平台成功入选住建部《2022年智慧水务典型案例》。
            </p>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农饮/智慧水务典型案例.png")}
              alt=""
            />
            <p>
              平台融远程调度、水质监测预警、管网地理信息、水厂自动化、安全管理、管网巡检等八大功能于一体，通过对仪表关键数据采集进行设备的远程自动化控制、可视化展示、异常数据预警，实现农村规模化水厂无人值守，为探索农村智慧水务建设运营模式，推动智慧水务行业发展提供了可复制、可推广的经验。
            </p>

            <b>1.智慧水务数据可视化 实现水务“一张图”管理</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农饮/一张图.gif")}
              alt=""
            />
            <p>
              建设农村供水一张图，将供水、管网数据通过大屏可视化图表及GIS地图形式展示，实现对设备、报警信息、视频监控、综合分析、水质等数据统一展示并创建了多个专题图层。目前已入库的所有测绘管网数据均已入库，登录的时间、查询管网信息及属性数据的时间不大于3秒，系统支持100个以上的并发用户，且50个并发用户在线时，WEB发布的实时画面的刷新时间不大于1秒，进行供水管网信息系统下的业务要求操作，例如超过6000户的用户信息导出，响应时间不超过30秒，高效完成空间信息的查询分析操作。
            </p>
            <b>2.数字孪生水厂，实现数字化运营</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农饮/数字孪生.png")}
              alt=""
            />
            <p>
              采用全三维3D实景仿真技术建立数字孪生水厂，对大场水厂厂区内道路、建筑、房间、设备、管线、主要工艺做精细化建模，整合场内各类硬件，还原虚拟现场，完成数字建筑的虚拟化；同时，将水厂SCADA、设备资产管理、安防等数据融为一体，实现大场水厂全数字化在线运营。
            </p>
            <b>3.生产运行集中管理，降本增效</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农饮/生产运行.gif")}
              alt=""
            />
            <p>
              通过优化实施集中生产调度运营模式，实现生产调度集中化，提高了生产效率，有效的降低生产运营中的电耗、药耗等成本消耗，为成本分析和追溯提供依据，保证生产系统更加安全、可靠、经济地运行。
            </p>
            <p>
              通过维修维护资源整合，实现集中维修维护（资产管理系统-设备资产检测报警、工单任务生成流转、维修人员维修处理、管理人员派发验收），工单处理时间缩短30%，维修效率提高20%-30%，大大增强了管理执行效率，保障设备运行安全。
            </p>

            <p>
              通过农村供水“智慧水务”中心调度平台建设，人员可以精简30%，每年节省人工成本约370万元；通过智能远传水表的安装，实现了分区计量，管网漏失率从原来30%—50%降低至20%以内；通过水质预警系统和自动加药系统，在第一时间了解水质情况下，自动调整药剂投加量，实现精准投加。同时，该平台也能实现周界报警、管网巡检，大大节省了人力物力，为保障供水安全筑起“智慧防火墙”。
            </p>
            <p>
              供水安全关系千家万户，惠及广大民生。下一阶段，青岛巨川将继续推动高品质水处理行业建设，提高农村供水安全化、智慧化运行水平，给予农村群众更多的获得感、幸福感，为青岛西海岸提供更好的供水保障。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetails;
