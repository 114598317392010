import React, { useState } from "react";
import { Pagination } from "antd";
import "./Clientcase.scss";
import { useNavigate } from "react-router-dom";
import useInView from "../../components/hook/hook";
function importAll(r) {
  let images = [];
  r.keys().forEach((item, index) => {
    images.push(r(item));
  });
  return images;
}

const imagesArr = [
  importAll(
    require.context(
      "../../assets/imgs/client/茌平县城乡供水一体化建设（东邢水厂）项目工程 聊城市茌平东邢水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/德州禹城市润禹水务有限公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/甘肃省白银有色动力有限公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/河北省沧州市青县地表水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/河北省沧州市中捷水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/河北省张家口市尚义农村饮水工程—尚义大青沟水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/河北省张家口市尚义县大青沟污水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/河南长垣新水水务有限公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/晖泽水务（青州）有限公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/莱阳市自来水公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/莱州市渤海水务云峰水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/聊城市莘县古云水库净水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/聊城市莘县莘州水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/临沂实康水务有限公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/农村饮水设施改造提升二期工程/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛胶东国际机场/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛市董家口及泊里分水供给工程—蒋家庄水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛市古镇口大学城污水站/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛水务胶州前韩水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛西海岸公用事业集团水务有限公司 高家台水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛西海岸公用事业集团水务有限公司 石河头水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛西海岸农村污水智慧管控平台工程/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛西海岸新区泥布湾污水处理厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛西海岸新区农饮改造提升膜处理工程/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/青岛西海岸新区农饮提升改造工程 大场水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/上海医药集团青岛国风药业有限公司/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/泰安市徂汶净水厂建设项目/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/潍坊市诸城青墩水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/烟台市招远市自来水公司杨家大沟净水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/烟台市招远辛庄净水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
  importAll(
    require.context(
      "../../assets/imgs/client/枣庄滕州市东沙河地表水厂/",
      false,
      /\.(png|jpe?g|svg|JPG)$/
    )
  ),
];
const array = [
  {
    id: 25,
    title: "青岛西海岸新区农饮提升改造工程 大场水厂",
    imgUrl: imagesArr[24][0],
    p: "",
    images: imagesArr[24],
  },
  {
    id: 21,
    title: "青岛西海岸公用事业集团水务有限公司 石河头水厂",
    imgUrl: imagesArr[20][0],
    p: "",
    images: imagesArr[20],
  },
  {
    id: 19,
    title: "青岛水务胶州前韩水厂",
    imgUrl: imagesArr[18][0],
    p: "",
    images: imagesArr[18],
  },
  {
    id: 15,
    title: "农村饮水设施改造提升二期工程",
    imgUrl: imagesArr[14][0],
    p: "",
    images: imagesArr[14],
  },
  {
    id: 22,
    title: "青岛西海岸农村污水智慧管控平台工程",
    imgUrl: imagesArr[21][0],
    p: "",
    images: imagesArr[21],
  },
  {
    id: 7,
    title: "河北省张家口市尚义县大青沟污水厂",
    imgUrl: imagesArr[6][0],
    p: "",
    images: imagesArr[6],
  },
  {
    id: 20,
    title: "青岛西海岸公用事业集团水务有限公司 高家台水厂",
    imgUrl: imagesArr[19][0],
    p: "",
    images: imagesArr[19],
  },
  {
    id: 1,
    title: "茌平县城乡供水一体化建设（东邢水厂）项目工程 聊城市茌平东邢水厂",
    imgUrl: imagesArr[0][0],
    p: "",
    images: imagesArr[0],
  },
  {
    id: 4,
    title: "河北省沧州市青县地表水厂",
    imgUrl: imagesArr[3][0],
    p: "",
    images: imagesArr[3],
  },
  {
    id: 6,
    title: "河北省张家口市尚义农村饮水工程—尚义大青沟水厂",
    imgUrl: imagesArr[5][0],
    p: "",
    images: imagesArr[5],
  },
  {
    id: 9,
    title: "晖泽水务（青州）有限公司",
    imgUrl: imagesArr[8][0],
    p: "",
    images: imagesArr[8],
  },
  {
    id: 11,
    title: "莱州市渤海水务云峰水厂",
    imgUrl: imagesArr[10][0],
    p: "",
    images: imagesArr[10],
  },
  {
    id: 12,
    title: "聊城市莘县古云水库净水厂",
    imgUrl: imagesArr[11][0],
    p: "",
    images: imagesArr[11],
  },
  {
    id: 13,
    title: "聊城市莘县莘州水厂",
    imgUrl: imagesArr[12][0],
    p: "",
    images: imagesArr[12],
  },
  {
    id: 14,
    title: "临沂实康水务有限公司",
    imgUrl: imagesArr[13][0],
    p: "",
    images: imagesArr[13],
  },
  {
    id: 18,
    title: "青岛市古镇口大学城污水站",
    imgUrl: imagesArr[17][0],
    p: "",
    images: imagesArr[17],
  },
  {
    id: 23,
    title: "青岛西海岸新区泥布湾污水处理厂",
    imgUrl: imagesArr[22][0],
    p: "",
    images: imagesArr[22],
  },
  {
    id: 24,
    title: "青岛西海岸新区农饮改造提升膜处理工程",
    imgUrl: imagesArr[23][0],
    p: "",
    images: imagesArr[23],
  },
  {
    id: 26,
    title: "上海医药集团青岛国风药业有限公司",
    imgUrl: imagesArr[25][0],
    p: "",
    images: imagesArr[25],
  },
  {
    id: 27,
    title: "泰安市徂汶净水厂建设项目",
    imgUrl: imagesArr[26][0],
    p: "",
    images: imagesArr[26],
  },
  {
    id: 28,
    title: "潍坊市诸城青墩水厂",
    imgUrl: imagesArr[27][0],
    p: "",
    images: imagesArr[27],
  },
  {
    id: 29,
    title: "烟台市招远市自来水公司杨家大沟净水厂",
    imgUrl: imagesArr[28][0],
    p: "",
    images: imagesArr[28],
  },
  {
    id: 30,
    title: "烟台市招远辛庄净水厂",
    imgUrl: imagesArr[29][0],
    p: "",
    images: imagesArr[29],
  },
  {
    id: 31,
    title: "枣庄滕州市东沙河地表水厂",
    imgUrl: imagesArr[30][0],
    p: "",
    images: imagesArr[30],
  },
  {
    id: 3,
    title: "甘肃省白银有色动力有限公司",
    imgUrl: imagesArr[2][0],
    p: "",
    images: imagesArr[2],
  },
  {
    id: 5,
    title: "河北省沧州市中捷水厂",
    imgUrl: imagesArr[4][0],
    p: "",
    images: imagesArr[4],
  },
  {
    id: 2,
    title: "德州禹城市润禹水务有限公司",
    imgUrl: imagesArr[1][0],
    p: "",
    images: imagesArr[1],
  },
  {
    id: 8,
    title: "河南长垣新水水务有限公司",
    imgUrl: imagesArr[7][0],
    p: "",
    images: imagesArr[7],
  },
  {
    id: 10,
    title: "莱阳市自来水公司",
    imgUrl: imagesArr[9][0],
    p: "",
    images: imagesArr[9],
  },
  {
    id: 16,
    title: "青岛胶东国际机场",
    imgUrl: imagesArr[15][0],
    p: "",
    images: imagesArr[15],
  },
  {
    id: 17,
    title: "青岛市董家口及泊里分水供给工程—蒋家庄水厂",
    imgUrl: imagesArr[16][0],
    p: "",
    images: imagesArr[16],
  },
];
const ClientCase = () => {
  // 分页
  const pageSize = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = array.length;

  const currentData = array.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // 处理页码改变事件
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 跳转至详情页
  const navigate = useNavigate();
  const toCaseDetails = (item) => {
    navigate("/client/case_details", {
      state: { case_details: item, case_array: array },
    });
  };
  //监听
  const [targetRef, inView] = useInView();
  const [targetRef2, inView2] = useInView();
  return (
    <div className="client_box">
      {/* 客户案例 */}
      <div className="case">
        <div
          ref={targetRef}
          className={inView ? "title animationInX" : "title"}
        >
          客户案例
        </div>
        <div
          ref={targetRef}
          className={inView ? "title2 animationup" : "title2"}
        >
          <span className="ico"></span>CUSTOMER STORIES
        </div>

        <div
          ref={targetRef2}
          className={inView2 ? "content animationup" : "content"}
        >
          {currentData.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                style={{
                  backgroundImage: `url(${item.imgUrl})`,
                }}
                onClick={() => toCaseDetails(item)}
              >
                <div className="item_sm">{item.title}</div>
              </div>
            );
          })}
        </div>
        {/* <div className="footer">
          <div className="btn">
            <span>更多案例</span>
          </div>
        </div> */}
      </div>
      {/* 分页 */}
      <div className="page">
        <Pagination
          defaultCurrent={currentPage}
          total={totalItems}
          pageSize={pageSize}
          className="pagination"
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export { ClientCase, array };
