import React from "react";
import "./NewsDetails1.scss";
import useInView from '../../../components/hook/hook'
const NewsDetails = () => {
    //监听
    const [targetRef, inView] = useInView();
    const [targetRef1, inView1] = useInView();
    const [targetRef2, inView2] = useInView();
  return (
    <div className="newsdetails1_main">
      <div className="newsdetail_box">
        {/* 头部 */}
        <div ref={targetRef} className={inView ? "news1_top animationup":"news1_top"}>
          <h1>焕新前行 共赴未来——巨川智能环保水处理产业园启动仪式成功举办！</h1>
          <img
            loading="lazy"
            src={require("../../../assets/imgs/news/新闻4_产业园建立/1.png")}
            alt=""
          />
          <p>
            孟夏草长，花开五月，巨川智能环保水处理产业园启动仪式以“焕新前行
            共赴未来”为主题，于2023年5月21日顺利举办！孟夏草长，花开五月，巨川智能环保水处理产业园启动仪式以“焕新前行
            共赴未来”为主题，于2023年5月21日顺利举办！
          </p>
        </div>
        {/* 中间 */}
        <div className="news1_middle">
          <div ref={targetRef1} className={inView1 ? "t1 animationleft":"t1"}>
            <h2>一、启动仪式</h2>
            <p>
              5月21日上午，巨川智能环保水处理产业园启动仪式顺利开始，青岛开发区管委副主任李博，青岛西海岸新区国际招商促进中心党组书记、主任韩洪一，国家工业水处理工程技术研究中心、中国城市规划设计研究院、山东省城市供排水水质监测中心等有关单位领导和专家，西海岸新区、青岛开发区相关部门和王台街道党工委副书记、办事处主任丁树亮等有关领导参加活动。
            </p>
            <div className="imgBox1 imgBox">
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/2.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/3.JPG")}
                alt=""
              />
            </div>
            <p>
              青岛巨川销售公司总经理赵成海主持巨川智能环保水处理产业园启动仪式。戈学珍董事长、严以强教授、李博主任发表致辞，对巨川智能环保水处理产业园的投产运营表示热烈的祝贺，对在巨川智能环保水处理产业园项目筹建过程中给予关怀和帮助的领导、朋友及社会各界同仁表示衷心的感谢！
            </p>
            <p>
              10点38分，各位领导和专家上台，为巨川智能环保水处理产业园的启动共同推杆。一杆到底，金沙显字，金纸飞扬。至此，巨川智能环保水处理产业园启动仪式圆满礼成。
            </p>
            <div className="imgBox2 imgBox">
              <img
                loading="lazy"
                className="littleImg"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/5.png")}
                alt=""
              />
              <img
                loading="lazy"
                className="littleImg"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/4.png")}
                alt=""
              />
              <img
                loading="lazy"
                className="littleImg"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/6.png")}
                alt=""
              />
              <img
                loading="lazy"
                className="littleImg"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/7.png")}
                alt=""
              />
              <img
                loading="lazy"
                className="middleImg"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/8.JPG")}
                alt=""
              />
            </div>
          </div>
          <div ref={targetRef2} className={inView2 ? "t2 animationright":"t2"}>
            <h2>二、答谢午宴</h2>
            <p>
              启动仪式结束后，青岛巨川于七楼宴会厅举办答谢午宴，各界朋友、嘉宾出席此次宴会。午宴开始前，戈学珍董事长及吴明松博士发表致辞，在社会各界人士的殷切期盼下，青岛巨川必定不懈努力、不负众望、蓬勃发展、续创辉煌！
            </p>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/新闻4_产业园建立/9.png")}
              alt=""
            />
            <div className="imgBox1 imgBox">
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/10.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
            </div>
            <p>
              炫丽的灯光秀、精彩绝伦的节目逐一上演，午宴现场高潮迭起，巨川人用默契的舞步、美妙的歌喉、搞笑的小品和动人的朗诵等出色的演出深深吸引住了台下嘉宾的注意，引发全场鼓掌叫好。
            </p>
            <div className="imgBox1 imgBox">
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/12.jpg")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/26.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/23.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/14.JPG")}
                alt=""
              />
            </div>

            <p>
              除了有精彩的演出，此次宴会还有激动人心的抽奖环节，奖品包括美的电烤箱、海尔零下40℃减霜智控冰柜、卡萨帝冰吧，在此再次祝贺抽到奖品的幸运儿。
            </p>
            <div className="imgBox1 imgBox">
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/27.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/28.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/29.JPG")}
                alt=""
              />
            </div>
            <p>
              午宴最后，巨川全体员工带来了手势舞表演《一家人》，巨川就如同一个大家庭一般，“因为我们是一家人，相依相惜彼此都感恩；因为我们是一家人，分担分享彼此的人生”，巨川人彼此信任，互相帮助，是对歌词的真实写照。至此，答谢午宴圆满结束。
            </p>

            <div className="imgBox1 imgBox">
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/30.JPG")}
                alt=""
              />
              <img
                loading="lazy"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/31.JPG")}
                alt=""
              />
            </div>
            <p>
              十五年薪火相传，十五年弦歌不断，巨川智能环保水处理产业园的启用，是对产能的集中整合和提档升级，标志着青岛巨川的发展又迈上了一个新的台阶。未来，青岛巨川将继续加大研发创新和市场拓展，增强核心人才的培养，积极促进高端集成化水处理设备研发和制造产业的可持续发展。
            </p>
            {/* <div class="grid">
              <img
                class="a"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="b"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="c"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="d"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="e"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="f"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="g"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="h"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
              <img
                class="i"
                src={require("../../../assets/imgs/news/新闻4_产业园建立/11.JPG")}
                alt=""
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetails;
