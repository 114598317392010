import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import "./Client.scss";
import { Tabs } from "antd";
import { Outlet } from "react-router-dom";
import useInView from "../../components/hook/hook";
const Client = () => {
  const text = "客户案例";
  const text_yw = "CASES";
  const imgUrl = require("../../assets/imgs/client/case_banner.jpg");
  const items = [{ key: "1", label: "客户案例" }];
  //监听
  const [targetRef, inView] = useInView();
  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="client">
      {/* banner */}
      <Banner data={{ text, text_yw, imgUrl }} />
      {/* middle  */}
      <div className="middleBox">
        <div className="middle w95">
          <div
            ref={targetRef}
            className={inView ? "left animationleft" : "left"}
          >
            <Tabs defaultActiveKey="1" items={items} />
          </div>
          <div
            ref={targetRef}
            className={inView ? "position animationright" : "position"}
          >
            <img
              loading="lazy"
              src={require("../../assets/imgs/client/pos_ico.png")}
              alt=""
            />
            您当前的位置：
            <a href="/">首页</a>
            <a href="/#/client"> / {text}</a>
            {/* <span> / {text}</span> */}
          </div>
        </div>
      </div>
      <Outlet></Outlet>
    </div>
  );
};
export default Client;
