import About from "../views/about/About";
import Home from "../views/home/Home";
import Not404 from "../views/404/Not404";
import Client from "../views/client/Client";
import News from "../views/news/News";
import Products from "../views/products/Products";
import Settle from "../views/settle/Settle";
import First from "../views/first/First";
import Relation from "../views/Relation/Relation";
import Productsltem_index from "../views/products/productsItem/productsltem_index/Productsltem_index";
import Productsltem_tow from "../views/products/productsItem/productsltem_tow/productsltem_tow";
import ClientCaseDetails from "../views/client/ClientCaseDetail";
import { ClientCase } from "../views/client/Clientcase";
import { NewsList } from "../views/news/NewsList";
import NewsDetails from "../views/news/NewsDetails";
import Secrecy from "../views/secrecy/Secrecy";

export default [
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "",
        element: <First />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/client",
        element: <Client />,
        children: [
          { path: "/client", element: <ClientCase /> },
          {
            path: "/client/case_details",
            element: <ClientCaseDetails />,
          },
        ],
      },
      {
        path: "/news",
        element: <News />,
        children: [
          { path: "/news", element: <NewsList /> },
          {
            path: "/news/news_details",
            element: <NewsDetails />,
          },
        ],
      },
      {
        path: "/products",
        element: <Products />,
        children: [
          {
            path: "/products",
            element: <Productsltem_index />,
          },
          {
            path: "/products/products_tow",
            element: <Productsltem_tow />,
          },
        ],
      },
      {
        path: "/settle",
        element: <Settle />,
      },
      {
        path: "/relation",
        element: <Relation />,
      },
      {
        path: "/secrecy",
        element: <Secrecy />,
      },
    ],
  },
  {
    path: "*",
    element: <Not404 />,
  },
];
