import React, { useRef } from "react";
import "./ClientCaseDetail.scss";
import CaseSwiper from "./CaseSwiper";
import CaseFirstSwiper from "./CaseFirstSwiper";
import { useLocation } from "react-router-dom";
import useInView from '../../components/hook/hook'
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import _ from "lodash";
const ClientCaseDetails = () => {
  const caseDetailRef = useRef(null);
  const handleItemClick = () => {
    if (caseDetailRef.current) {
      caseDetailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const location = useLocation();

  const state = _.get(location, ["state"]);
  const case_details = state.case_details;
  const images = case_details.images;
  const array = state.case_array;
//监听
const [targetRef, inView] = useInView();
const [targetRef2, inView2] = useInView();
  // 网格
  return (
    <div className="ClientCaseDetails_box" ref={caseDetailRef}>
      <div className="ClientCaseDetails">
        <div className="casedetailBg">
          <div className="caseDetail" id="caseDetail">
            <div ref={targetRef2} className={inView2 ? "casecontent animationup":"casecontent"}>
              <h1>{case_details.title}</h1>
              {/* <img src={case_details.imgUrl} alt="" /> */}
              <p>{case_details.p}</p>
              <CaseFirstSwiper images={images} />
            </div>
          </div>
        </div>
        <div className="caseswiperBox">
          <CaseSwiper array={array} handleItemClick={handleItemClick} />
        </div>
      </div>
    </div>
  );
};
export default ClientCaseDetails;
