import React, { useEffect, useState } from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// 导入 Swiper 和 SwiperSlide 组件
import { Swiper, SwiperSlide } from 'swiper/react';
import useInView from '../../components/hook/hook'
import 'swiper/swiper-bundle.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import 'swiper/css/effect-fade';

import './About.scss'
const Swiper3 = () => {
        //监听
        const [targetRef, inView] = useInView();
    const [num, setNum] = useState(window.innerWidth > 900 ? 5 : 1)
      // 荣誉资质
  const ryzz = [
    {
        id: 1, imgUrl: 'http://1.94.15.227/image/3A立信单位-20240318034559877.jpg'
    },
    
    {
        id: 2, imgUrl: 'http://1.94.15.227/image/3A诚信单位-20240318034305953.jpg'
    },
    {
        id: 3, imgUrl: 'http://1.94.15.227/image/3A诚信单位-20240318034305953.jpg'
    },
    {
        id: 4, imgUrl: 'http://1.94.15.227/image/诚信供应商-20240318035208745.jpg'
    },
    {
        id: 5, imgUrl: 'http://1.94.15.227/image/诚信供应商-20240318035208745.jpg'
    },
    {
        id: 6, imgUrl: 'http://1.94.15.227/image/企业信用等级-20240318035433608.jpg'
    },
    // {
    //     id: 7, imgUrl: 'http://1.94.15.227/image/3A诚信企业家-20240318034449653.jpg'
    // },
    {
        id: 8, imgUrl: 'http://1.94.15.227/image/信用企业-2024031803550341.jpg'
    },
    {
        id: 9, imgUrl: 'http://1.94.15.227/image/诚信经营示范单位-20240318035343163.jpg'
    },
    // {
    //     id: 10, imgUrl:'http://1.94.15.227/image/诚信职业经理人-20240318035401879.jpg'
    // },
    {
        id: 11, imgUrl:'http://1.94.15.227/image/质量服务诚信单位-2024031803552091.jpg'
    },
    {
        id: 12, imgUrl:'http://1.94.15.227/image/重合同守信用单位-20240318035535663.jpg'
    },
    {
        id: 13, imgUrl:'http://1.94.15.227/image/打码替换-电子与智能化工程专业承包资质证书-20240318035637809.jpg'
    },
    {
        id: 14, imgUrl:'http://1.94.15.227/image/打码替换-建筑业企业资质证书-20240318040823908.jpg'
    },
    {
        id: 15, imgUrl:'http://1.94.15.227/image/环境管理体系认证证书2024.1.12_00-20240318040853844.jpg'
    },
    {
        id: 16, imgUrl:'http://1.94.15.227/image/科学技术进步奖-20240318040922311.jpg'
    },
    {
        id: 17, imgUrl:'http://1.94.15.227/image/危化品23.6.2-20240318040939144.jpg'
    },
    {
        id: 18, imgUrl:'http://1.94.15.227/image/专精特新中小企业证书(1)-20240318041058755.jpg'
    },
    {
        id: 19, imgUrl:'http://1.94.15.227/image/消毒卫生许可证24.2.29_00-20240318040957365.jpg'
    },
    {
        id: 20, imgUrl:'http://1.94.15.227/image/职业健康安全管理体系认证证书截止24.8.25_00-20240318041015345.jpg'
    },
    {
        id: 21, imgUrl:'http://1.94.15.227/image/职业健康安全管理体系认证证书截止24.8.25_00-20240318041044702.jpg'
    },
    {
        id: 22, imgUrl:'http://1.94.15.227/image/质量管理体系认证证书截止24.8.25_00-20240318041031136.jpg'
    },
    
]
    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth < 1024 && window.innerWidth >= 900) {
            setNum(3)
        } else if (window.innerWidth < 900) {
            setNum(1)
        } else {
            setNum(5)
        }}
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div ref={targetRef} className={inView ? "Swiper3 animationleft" : 'Swiper3'}>
           
            <Swiper
             modules={[Navigation, Pagination, Scrollbar, A11y]}
             navigation
             onSwiper={(swiper) => console.log(swiper)}
             onSlideChange={() => console.log('slide change')}
             
                slidesPerView={num}
                spaceBetween={40}
                centeredSlides={true}
                loop={true}
                freeMode={true}//切换反弹
                autoplay={true}
                pagination={{
                    clickable: true
                }}
            >
                <div class="swiper-wrapper">
                    {ryzz.map((item, index) => (
                        <SwiperSlide
                            key={index}
                        >
                            <img src={item.imgUrl} alt="" />
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
        </div>

    )
}
export default Swiper3