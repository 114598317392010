import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowRightOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./SOLUTION.scss";
const SOLUTION = () => {
  //选项卡
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="figure">
      
        <div className="tab-content">
          {activeTab === "tab1" && (
            <div>
              <div className="text text1">
                <h1>智慧排水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">排水防涝高效应急调度</div>
                <div className="content">
                  系统融合气象、水利、水文等信息资源，依托物联网在线监测技术、云计算和大数据技术等先进手段，建立一个集感知、分析、服务、
                  指挥、巡查五位一体的排水防涝监控体系，构建可视化的防洪排涝“一张图”和高效的应急指挥调度管理体系，提升风险防范和处置能力。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab2" && (
            <div>
              <div className="text text2">
                <h1>智慧排水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">排水管网运行监测</div>
                <div className="content">
                  以专业化普查检测服务、管网运行监测、专业分析、运维养护管理系统，建立四位一体的运行监测体系，
                  实现管网及附属设施信息化管理、运行实时监测和精细化运维管理，提升管网运维管理水平，保障管网健康通畅运行。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab3" && (
            <div>
              <div className="text text3">
                <h1>智慧排水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">分散式污水设施运行管控</div>
                <div className="content">
                  系统通过对分散式污水设施进行智能化改造和数据采集接入，获取设备和仪表的在线监测数据，
                  实现污水处理设施运行情况监控、数据智能化分析处理与可视化展现、远程智能控制，发挥分散式污水处理设施价值与作用，同时降低运营单位的风险和成本。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab4" && (
            <div>
              <div className="text text4">
                <h1>智慧排水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">污水厂自动化控制系统</div>
                <div className="content">
                  污水厂自动化监控系统，采用二级管理的计算机监控系统，其上位系统位于中控室，用于对全厂的所有站点设备进行监控，
                  下级控制系统位于各个工艺段PLC站点。上下级通过通信网络进行数据交换，实现上位系统对所有站点设备的实时监视和远程控制。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
          {activeTab === "tab5" && (
            <div>
              <div className="text text5">
                <h1>智慧排水解决方案</h1>
                <div className="em"></div>
                <div>
                  <span className="ico"></span>SOLUTION
                </div>
                <div className="titel">智能远传抄表</div>
                <div className="content">
                  系统通过自主开发的智能算法对各类生产运行数据进行可视化分析，为客户提供多方位的污水拉运处理服务，
                  能够实现污水池水位监控、智能告警、运营分析、拉运调度预案管理、工单管理等业务，提升企业智能化、精细化、科学化管理水平。
                </div>
                <div>
                  <Link className="btn" to="/settle">
                    查看详情 <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="tab-buttons_t">
          <div className="tab-buttons">
            <div
              onClick={() => handleTabClick("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab1"
                      ? require("../assets/imgs/first_imgs/解决方案/排水调度2.png")
                      : require("../assets/imgs/first_imgs/解决方案/排水调度.png")
                  }
                  alt=""
                />
                <div>排水调度</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab2")}
              className={activeTab === "tab2" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab2"
                      ? require("../assets/imgs/first_imgs/解决方案/排水管网2.png")
                      : require("../assets/imgs/first_imgs/解决方案/排水管网.png")
                  }
                  alt=""
                />
                <div>排水管网</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab3")}
              className={activeTab === "tab3" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab3"
                      ? require("../assets/imgs/first_imgs/解决方案/分散模块2.png")
                      : require("../assets/imgs/first_imgs/解决方案/分散模块.png")
                  }
                  alt=""
                />
                <div>分散模块</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab4")}
              className={activeTab === "tab4" ? "active" : ""}
            >
              <div className="tab-buttons_tab tab-buttons_tab_after">
                <img
                  src={
                    activeTab === "tab4"
                      ? require("../assets/imgs/first_imgs/解决方案/污水自控2.png")
                      : require("../assets/imgs/first_imgs/解决方案/污水自控.png")
                  }
                  alt=""
                />
                <div>污水自控</div>
              </div>
            </div>
            <div
              onClick={() => handleTabClick("tab5")}
              className={activeTab === "tab5" ? "active" : ""}
            >
              <div className="tab-buttons_tab ">
                <img
                  src={
                    activeTab === "tab5"
                      ? require("../assets/imgs/first_imgs/解决方案/智能拉运2.png")
                      : require("../assets/imgs/first_imgs/解决方案/智能拉运.png")
                  }
                  alt=""
                />
                <div>智能拉运</div>
              </div>
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default SOLUTION;
