import React from 'react'
import { HashRouter, Routes, Route } from "react-router-dom"
import routes from './router'

export default function App() {
    const getRoute = (routes) => {
        return routes.map(item => {
            if (item.children) {
                return <Route path={item.path} element={item.element} key={item.path}>
                    {
                        getRoute(item.children)
                    }
                </Route>
            } else {
                return <Route path={item.path} element={item.element} key={item.path}></Route>
            }
        })
    }
    return (
        <HashRouter>
            {/* 出口 */}
            <Routes>
                {getRoute(routes)}
            </Routes>
        </HashRouter>
    )
}