import React from "react";
import "./NewsDetails1.scss";
import useInView from '../../../components/hook/hook'
const NewsDetails = () => {
      //监听
      const [targetRef, inView] = useInView();
      const [targetRef1, inView1] = useInView();
      const [targetRef2, inView2] = useInView();
  return (
    <div className="newsdetails1_main">
      <div className="newsdetail_box">
        {/* 头部 */}
        <div ref={targetRef} className={inView ? "news1_top animationup":"news1_top"}>
          <h1>青岛西海岸农村生活污水智慧平台：开启农村生活污水治理新模式！</h1>
          <p>
            农村污水处理站点与城市相比规模小，分散性强，广泛存在运行监测困难、故障处理不及时等情况。如何采取适当的数字化运营管理技术，增强监测预警及任务调度能力，从而提高农村污水处理的可靠效率，保障农村环境和公共卫生？青岛西海岸农村生活污水智慧平台提供了一个发展思路。
          </p>
        </div>
        {/* 中间 */}
        <div className="news1_middle">
          <div ref={targetRef1} className={inView1 ? "t1 animationleft":"t1"}>
            <h2>一、平台简介</h2>
            <p>
              为建设美丽乡村，青岛西海岸公用事业集团有限公司积极推动青岛西海岸农村污水治理工程的开展，由青岛巨川环保科技有限公司承建的农村污水智慧平台，覆盖大场、海青、张家楼等10个镇街，服务人口约25万。集站点实时监控预警、任务协同管理、数据分析等功能，对促进农村污水处理系统向自动化、智慧化转变具有重要意义。
            </p>
          </div>
          <div ref={targetRef2} className={inView2 ? "t2 animationright":"t2"}>
            <h2>二、平台特点</h2>
            <b>1、全管控，构建智慧水务管理调度指挥中心</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农污/1.png")}
              alt=""
            />
            <p>
              ◆平台集运行监视、视频监控、事故报警、PLC自控、移动办公、数据采集、处理和分析、控制与调度于一体，满足综合监督、辅助实时调度决策，进而支持优化调度的需求。
            </p>
            <p>
              ◆对领导层、各运维管理和技术部门及时掌握生产状况和作出决策提供了第一手数据资料。
            </p>
            <p>
              ◆展示本项目智慧污水数字化管理系统建设管理的窗口，负责整个平台的管理、运营维护和展示，可接入各类城市建设与管理数据与信息。
            </p>

            <b>2、智能调度，搭建科学实用的收运管理平台</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农污/2.png")}
              alt=""
            />
            <p>
              ◆通过收运看板对抽运过程中的数据进行统计并展示，污水池管理涵盖298个村庄424座污水收集池、148座污水泵站、231座污水模块、8个管网卸水点基础信息，根据收集池液位调度车辆进行污水抽运。
            </p>
            <p>
              ◆智能调度，分析片区负责车辆，及时生成自动任务并通知车辆，减少人工干预，提高容错率。
            </p>
            <p>
              ◆实时地图掌控，可查询车辆行驶轨迹和车辆作业详情，实现对62辆拉运车拉运过程的实时全程监控，包含车速、载重量、位置等信息，合理展示每日拉运现状。
            </p>
            <p>
              ◆智能告警，实现对污水拉运车辆各数据监控，提供车速、位置、装卸异常报警，及时通知管理人员。
            </p>

            <b>3、数据可视化，降低运营成本</b>
            <img
              loading="lazy"
              className="middleImg"
              src={require("../../../assets/imgs/news/农污/3.png")}
              alt=""
            />
            <p>
              ◆通过自主开发的智能算法对各类生产运行数据进行可视化，实现污水池水位监控、智能告警、运营分析、拉运调度预案管理、工单管理等业务。
            </p>
            <p>
              ◆自动生成各类报表，方便管理人员随时随地对污水处理全过程进行监控分析。及时发现问题，减轻工作人员工作量，指导运行生产，能够降低管理成本、药剂成本、设备维护保养成本及人力资源成本。
            </p>
            <p>◆实现车辆拉运数据精准计量，降低成本10%-20%。</p>
            <p>
              青岛西海岸农村生活污水智慧平台是青岛西海岸公用事业集团有限公司的一次创新性改革。在青岛市农村生活污水治理第三次推进会上，全市各区行业主管部门对农村污水智慧平台进行现场考察调研，对平台功能和效率给予高度评价。未来，青岛巨川环保科技有限公司将继续携手青岛西海岸公用事业集团有限公司，将农村生活污水智慧平台打造成为省级示范标杆项目，为各市农村污水治理工作提升和转型提供样板支撑。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetails;
