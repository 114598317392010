import React, { useEffect } from "react";
import "./NewsList.scss";
import { useNavigate } from "react-router-dom";
import { List } from "antd";
import useInView from "../../components/hook/hook";

const data = [
  {
    id: 2,
    href: "https://ant.design",
    title: `青岛西海岸农村生活污水智慧平台：开启农村生活污水治理新模式！`,
    description:
      "为建设美丽乡村，青岛西海岸公用事业集团有限公司积极推动青岛西海岸农村污水治理工程的开展，由青岛巨川环保科技有限公司承建的农村污水智慧平台，覆盖大场、海青、张家楼等10个镇街，服务人口约25万。集站点实时监控预警、任务协同管理、数据分析等功能，对促进农村污水处理系统向自动化、智慧化转变具有重要意义。",
    date: "2024-02-28",
    image: require("../../assets/imgs/news/农污/1.png"),
  },
  {
    id: 3,
    href: "https://ant.design",
    title: `青岛西海岸新区：深入开展农村供水水质提升行动，农村供水安全有保障！`,
    description:
      "随着我国农村经济的不断发展，农村饮用水安全问题逐渐引起人们的广泛关注，从“喝上水”到“喝好水”，已成为人们的殷切期望。青岛作为北方资源性缺水城市，连续多年将农村饮水安全列入市办实事，推动实施多项农村供水提标升级项目，青岛供水安全保障能力有了巨大提升。",
    date: "2023-11-24",
    image: require("../../assets/imgs/news/农饮/一张图.gif"),
  },
  {
    id: 1,
    href: "https://ant.design",
    title: `焕新前行 共赴未来——巨川智能环保水处理产业园启动仪式成功举办！`,
    description:
      "孟夏草长，花开五月，巨川智能环保水处理产业园启动仪式以“焕新前行 共赴未来”为主题，于2023年5月21日顺利举办！",
    date: "2023-05-23",
    image: require("../../assets/imgs/news/新闻4_产业园建立/1.png"),
  },
  {
    id: 4,
    href: "https://ant.design",
    title: `重磅！《生活饮用水卫生标准》（GB5749-2022）正式发布！加强对消毒副产物指标的严格控制，确保饮用水供水安全！`,
    description:
      "2022年3月15日发布的《生活饮用水卫生标准》（GB 5749-2022），已于2023年4月1日正式实施。较前标准GB 5749-2006，《生活饮用水卫生标准》（GB5749-2022），加强对消毒副产物指标的严格控制，确保饮用水供水安全。",
    date: "2022-04-02",
    image: require("../../assets/imgs/news/饮用水标准/1.png"),
  },
];
const NewsList = () => {
  // 返回顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // 跳转至详情页
  const navigate = useNavigate();
  const toNewDetails = (item) => {
    // const dataIndex = data.findIndex((dataItem) => dataItem.id === item.id);
    // const newsArr = [data[dataIndex - 1], item, data[dataIndex + 1]];
    // sessionStorage.setItem("news_details", JSON.stringify(newsArr));
    // sessionStorage.setItem("newsData", JSON.stringify(data));
    // navigate("/news/news_details");
    console.log("item", item);
    const dataIndex = data.findIndex((dataItem) => dataItem.id === item.id);
    const newsArr = [data[dataIndex - 1], item, data[dataIndex + 1]];
    navigate("/news/news_details", {
      state: { news_details: newsArr, newsData: data },
    });
  };
  //监听
  const [targetRef, inView] = useInView();

  const NewList = () => (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: (page) => {
          // console.log(page);
        },
        pageSize: 3,
        showSizeChanger: false, // 禁用改变每页条数
        showQuickJumper: true, // 显示快速跳转到某一页的输入框
        position: "bottom", // 分页器位置，默认为 'bottom'，可选 'top'、'both'
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          className="news_itemBox"
          key={item.title}
          onClick={() => toNewDetails(item)}
          extra={
            <img
              loading="lazy"
              className="newsImg"
              alt="logo"
              src={item.image}
            />
          }
        >
          {/* {Number(item.id) === 1 && <i></i>} */}
          <h2>{item.title}</h2>
          <div className="description">
            {/* <img
              loading="lazy"
              src={require("../../assets/imgs/news/new.png")}
              alt=""
            /> */}
            <p>{item.description}</p>
          </div>
          <div className="date">
            {/* <img
              loading="lazy"
              src={require("../../assets/imgs/news/date.png")}
              alt=""
            /> */}
            <p>{item.date}</p>
          </div>

          <button className="btn">
            <span>了解更多</span>
          </button>
        </List.Item>
      )}
    />
  );
  return (
    <div
      ref={targetRef}
      className={inView ? "newlist_main animationup" : "newlist_main"}
    >
      <div className="newlist">
        {/* 新闻列表 */}
        <NewList />
      </div>
    </div>
  );
};
export { NewsList, data };
