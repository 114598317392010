import React, { useRef, useEffect, useState } from 'react';
import { EffectFade } from 'swiper/modules';
// import Swiper from 'swiper';
// 导入 Swiper 和 SwiperSlide 组件
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import useInView from '../../components/hook/hook'
import 'swiper/swiper-bundle.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import 'swiper/css/effect-fade';

import './About.scss'
const Swiper2 = () => {
    //监听
    const [targetRef, inView] = useInView();
    const [num, setNum] = useState(window.innerWidth > 900 ? 5 : 1)
    // 软件执著
    const rjzzq = [
        {
            id: 1, imgUrl: "http://1.94.15.227/image/打码替换-巨川深度处理自动化系统2.2-20240318041637279.jpg"
        },
        {
            id: 2, imgUrl: "http://1.94.15.227/image/打码替换-巨川水厂自动化系统v1.2-20240318041652733.jpg"
        },
        {
            id: 3, imgUrl: "http://1.94.15.227/image/打码替换-巨川智能化手机监测水厂净水系统v1.0-20240318041709775.jpg"
        },
        {
            id: 4, imgUrl: "http://1.94.15.227/image/打码替换-青岛巨川PAM全自动加药设备控制系统v1.0-20240318041723787.jpg"
        },
        {
            id: 5, imgUrl: "http://1.94.15.227/image/打码替换-青岛巨川换热站自动控制系统v1.0-20240318041734945.jpg"
        },
        {
            id: 6, imgUrl: "http://1.94.15.227/image/打码替换-青岛巨川蔗糖法二氧化氯设备控制系统v1.0-20240318041747119.jpg"
        },


    ]
    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth < 1024 && window.innerWidth >= 900) {
            setNum(3)
        } else if (window.innerWidth < 900) {
            setNum(1)
        } else {
            setNum(5)
        }}
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div ref={targetRef} className={inView ? "Swiper2 animationIn" : 'Swiper2'}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                slidesPerView={num}
                spaceBetween={40}
                centeredSlides={true}
                loop={true}
                freeMode={true}//切换反弹
                autoplay={true}
                pagination={{
                    clickable: true
                }}
            >
                <div class="swiper-wrapper">
                    {rjzzq.map((item, index) => (
                        <SwiperSlide
                            key={index}
                        >
                            <img src={item.imgUrl} alt="" />
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
        </div>

    )
}
export default Swiper2